import React from "react"
import {
    L4Protocol,
    L4RuleExtended,
    isAnyIpRange,
    isAnyPort,
    l4ProtocolLabels,
} from "../../../v3/services/Policy.service"
import styles from "./PolicyDestinationRow.module.scss"
import { TextArea } from "../../../components/input/TextArea.component"
import {
    LocalizationService,
    useServiceLocalization,
} from "../../../pre-v3/services/localization/Localization.service"
import { ToggleButton, ToggleButtonItem } from "../../../v3/components/toggle-button/ToggleButton"
import { Checkbox } from "../../../v3/components/checkbox/Checkbox.component"
import { Input } from "../../../components/input/Input.component"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { AccessType } from "./AccessPolicy.types"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import {
    MultiSelectInput,
    Option,
} from "../../../v3/components/multi-select-input/MultiSelectInput.component"
import { portCSVValidity, toLocalizedList } from "../../../utils/Strings.utils"
import { StringUtil } from "../../../pre-v3/utils/String.util"
import { findDuplicates } from "../../../utils/Array.utils"

export interface Props {
    policyDestination: L4RuleExtended
    disabled?: boolean
    disableDelete: boolean
    onDestinationChange: (destination: L4RuleExtended) => void
    onDeleteRow: () => void
    type?: AccessType
    enableRequiredCheck?: boolean
}

const setInitialValues = (policyDestination: L4RuleExtended): AccessType => {
    if (
        !policyDestination.fqdnList &&
        !policyDestination.ipRanges &&
        policyDestination.protocols.length > 0
    ) {
        return AccessType.IP_RANGES
    }

    if (policyDestination.ipRanges && !policyDestination.fqdnList) {
        return AccessType.IP_RANGES
    } else {
        return AccessType.FQDNS
    }
}

export function PolicyDestinationRow(props: Props) {
    const localization = useServiceLocalization()
    const { disabled = false } = props

    const [type, setType] = React.useState<AccessType>(() =>
        setInitialValues(props.policyDestination)
    )
    const [isPortAny, setIsPortAny] = React.useState<boolean>(isAnyPort(props.policyDestination))
    const [fqdns, setFqdns] = React.useState<string>(props.policyDestination.fqdnList)
    const [ipRanges, setIpranges] = React.useState<string>(props.policyDestination.ipRanges)

    const [isIpRangeAny, setIsIpRangeAny] = React.useState<boolean>(
        isAnyIpRange(props.policyDestination)
    )

    const [ports, setPorts] = React.useState<string[] | "ANY">(props.policyDestination.ports)

    const portsRef = React.useRef<HTMLInputElement>(null)
    const destinationRef = React.useRef<HTMLTextAreaElement>(null)

    const onIpRangesFqdnsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        const value = event.target.value

        value && validateIpRangesFqdns(destinationRef, localization, type)

        const updatedDestination = { ...props.policyDestination }
        const values = value
        if (type === AccessType.IP_RANGES) {
            setIpranges(values)
        } else {
            setFqdns(values)
        }
        type === AccessType.IP_RANGES
            ? (updatedDestination.ipRanges = values)
            : (updatedDestination.fqdnList = values)
        props.onDestinationChange(updatedDestination)
    }

    const onProtocolChange = (protocols: string | string[]) => {
        const destination = { ...props.policyDestination }
        if (protocols.includes(L4Protocol.ALL)) {
            destination.protocols = [L4Protocol.ALL]
            setIsPortAny(true)
            props.onDestinationChange({ ...destination, ports: "ANY" })
        } else if (protocols.includes(L4Protocol.ICMP)) {
            setIsPortAny(false)
            destination.protocols = protocols as L4Protocol[]
            if (
                protocols.includes(L4Protocol.ICMP) &&
                protocols.includes(L4Protocol.TCP) &&
                protocols.includes(L4Protocol.UDP)
            ) {
                setIsPortAny(true)
            } else {
                setIsPortAny(false)
            }
            props.onDestinationChange({ ...destination, ports: "ANY" })
        } else {
            setIsPortAny(false)
            destination.protocols = protocols as L4Protocol[]
            props.onDestinationChange({ ...destination, ports: ports })
        }
    }

    const onPortsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const value = event.target.value
        setPorts(value.split(",").map((port) => port.trim()))
        props.onDestinationChange({
            ...props.policyDestination,
            ports: value.split(",").map((port) => port.trim()),
        })
    }

    const onPortsAnyChange = (checked: boolean) => {
        setIsPortAny(checked)
        props.onDestinationChange({
            ...props.policyDestination,
            ports: checked ? "ANY" : ports,
        })
    }

    const onIpRangeChange = (checked: boolean) => {
        setIsIpRangeAny(checked)
        if (checked) {
            props.onDestinationChange({
                ...props.policyDestination,
                ipRanges: "ANY",
            })
        } else {
            props.onDestinationChange({
                ...props.policyDestination,
                ipRanges: ipRanges,
            })
        }
    }

    const onDeleteRow = () => {
        props.onDeleteRow()
        setIsIpRangeAny(false)
        setIsPortAny(false)
    }

    const onAccessTypeChange = (type: AccessType) => {
        setType(type)
        if (destinationRef.current?.value) {
            destinationRef.current.setCustomValidity("")
        }
        let iprange: string = ""
        if (type === AccessType.IP_RANGES) {
            iprange = isIpRangeAny ? "ANY" : ipRanges
        }
        const updatedDestination = {
            ...props.policyDestination,
            ipRanges: iprange,
            fqdnList: type === AccessType.FQDNS ? fqdns : "",
        }
        props.onDestinationChange(updatedDestination)
    }

    const onNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        const value = event.target.value
        props.onDestinationChange({
            ...props.policyDestination,
            description: value,
        })
    }

    const l4ProtocolsOptions = React.useMemo(
        () =>
            Object.values(L4Protocol).map<Option>((protocol) => ({
                value: protocol,
                displayName: localization.getString(l4ProtocolLabels[protocol]),
            })),
        [localization]
    )

    const ipRangesFqdnsPlaceholder = React.useMemo(() => {
        return type === AccessType.IP_RANGES
            ? localization.getString(
                  "commaSeparatedSomething",
                  localization.getString("commaSeparatedIpRanges")
              )
            : localization.getString(
                  "commaSeparatedSomething",
                  localization.getString("commaSeparatedFqdns")
              )
    }, [type])

    function getIpRangeOrFqdn() {
        if (type === AccessType.FQDNS) {
            return props.policyDestination.fqdnList
        } else {
            if (props.policyDestination.ipRanges === "ANY") {
                return ""
            } else {
                return props.policyDestination.ipRanges
            }
        }
    }

    function getPort(): string {
        if (
            props.policyDestination.ports === "ANY" ||
            props.policyDestination.protocols.includes(L4Protocol.ICMP)
        ) {
            return ""
        } else {
            return props.policyDestination.ports?.join(",")
        }
    }
    return (
        <tr className={styles.destinationRow}>
            <td className={styles.destinationField}>
                <div className={styles.toggleContainer}>
                    <DestinationTypeToggle
                        className={styles.destinationTypeToggle}
                        policyDestination={props.policyDestination}
                        onAccessTypeChange={onAccessTypeChange}
                        disabled={disabled}
                        accessType={type}
                    />
                    {type === AccessType.IP_RANGES && (
                        <Checkbox
                            checked={isIpRangeAny}
                            onChange={onIpRangeChange}
                            disabled={props.disabled}
                        >
                            {localization.getString("any")}
                        </Checkbox>
                    )}
                </div>

                <TextArea
                    className={styles.formTextArea}
                    placeholder={ipRangesFqdnsPlaceholder}
                    aria-label={localization.getString(
                        type === AccessType.IP_RANGES ? "ipRangesIps" : "fqdns"
                    )}
                    value={getIpRangeOrFqdn()}
                    disabled={props.disabled || (isIpRangeAny && type === AccessType.IP_RANGES)}
                    onChange={onIpRangesFqdnsChange}
                    required={props.enableRequiredCheck}
                    rows={3}
                    ref={destinationRef}
                />
            </td>

            <td>
                <MultiSelectInput
                    aria-label={localization.getString("protocols")}
                    className={styles.selectInput}
                    options={l4ProtocolsOptions}
                    value={props.policyDestination.protocols}
                    onChange={onProtocolChange}
                    disabled={disabled}
                    required={props.enableRequiredCheck}
                />
            </td>

            <td className={styles.ports}>
                <Checkbox
                    checked={isPortAny}
                    onChange={onPortsAnyChange}
                    disabled={
                        props.policyDestination.protocols.includes(L4Protocol.ALL) ||
                        props.policyDestination.protocols.includes(L4Protocol.ICMP) ||
                        props.disabled
                    }
                    aria-label="any"
                >
                    {localization.getString("any")}
                </Checkbox>

                <Input
                    ref={portsRef}
                    placeholder={localization.getString(
                        "commaSeparatedSomething",
                        localization.getString("commaSeparatedPorts")
                    )}
                    disabled={
                        props.disabled ||
                        isPortAny ||
                        props.policyDestination.protocols.includes(L4Protocol.ALL) ||
                        props.policyDestination.protocols.includes(L4Protocol.ICMP)
                    }
                    required={props.enableRequiredCheck}
                    value={getPort()}
                    onChange={onPortsChange}
                    onBlur={() => validatePorts(portsRef, localization)}
                />
            </td>

            <td className={styles.notes}>
                <TextArea
                    className={styles.formTextArea}
                    placeholder={localization.getString("addNotes")}
                    aria-label={localization.getString("addNotes")}
                    value={props.policyDestination.description}
                    disabled={props.disabled}
                    onChange={onNotesChange}
                    rows={3}
                />

                <Button
                    asElement={ButtonElement.BUTTON}
                    disabled={props.disabled || props.disableDelete}
                    type="button"
                    icon={IconType.TRASH}
                    buttonType={ButtonType.DESTRUCTIVE}
                    onClick={onDeleteRow}
                    className={styles.deleteButton}
                />
            </td>
        </tr>
    )
}

const validateIpRangesFqdns = (
    destinationRef: React.RefObject<HTMLTextAreaElement>,
    localization: LocalizationService,
    type: AccessType
) => {
    if (!destinationRef.current?.value) return

    switch (type) {
        case AccessType.IP_RANGES:
            validateIpRanges(destinationRef, localization)
            break
        case AccessType.FQDNS:
            validateFqdns(destinationRef, localization)
            break
        default:
            break
    }
}

const validateIpRanges = (
    destinationRef: React.RefObject<HTMLTextAreaElement>,
    localization: LocalizationService
) => {
    if (!destinationRef.current?.value) return

    const duplicates = findDuplicates(destinationRef.current.value.split(/,\s*/)).filter(
        (val) => val
    )
    const dupeList = toLocalizedList(duplicates, localization.getLocale(), "conjunction")
    if (duplicates.length > 0) {
        destinationRef.current.setCustomValidity(
            localization.getString(
                "errorThereAreDuplicatesSomething",
                ...[localization.getString("ipRangesIps"), dupeList]
            )
        )
        return
    }
    const correctValues = StringUtil.stringListToArrayList(destinationRef.current.value).every(
        (ip) => {
            if (ip.includes("/")) {
                return PatternUtil.CIDR_REGEX.test(ip)
            } else {
                return PatternUtil.IPV4_ADDRESS.test(ip)
            }
        }
    )

    validateDestinationRef(destinationRef, correctValues, localization.getString("invalidCidr"))
}

const validateFqdns = (
    destinationRef: React.RefObject<HTMLTextAreaElement>,
    localization: LocalizationService
) => {
    if (!destinationRef.current?.value) return

    const duplicates = findDuplicates(destinationRef.current.value.split(/,\s*/)).filter(
        (val) => val
    )
    const dupeList = toLocalizedList(duplicates, localization.getLocale(), "conjunction")
    if (duplicates.length > 0) {
        destinationRef.current.setCustomValidity(
            localization.getString(
                "errorThereAreDuplicatesSomething",
                ...[localization.getString("fqdns"), dupeList]
            )
        )
        return
    }
    const correctValues = StringUtil.stringListToArrayList(destinationRef.current.value).every(
        (fqdn) => PatternUtil.FQDN.test(fqdn)
    )
    validateDestinationRef(destinationRef, correctValues, localization.getString("invalidFqdns"))
}

const validateDestinationRef = (
    destinationRef: React.RefObject<HTMLTextAreaElement>,
    correctValues: Boolean,
    errorLabel: string
) => {
    if (!destinationRef.current?.value) return

    if (correctValues) {
        destinationRef.current.setCustomValidity("")
    } else {
        destinationRef.current.setCustomValidity(errorLabel)
    }
}

const validatePorts = (
    portsRef: React.RefObject<HTMLInputElement>,
    localization: LocalizationService
) => {
    if (!portsRef.current?.value) return

    if (PatternUtil.VALID_PORTS.test(portsRef.current.value.replace(/,+$/, ""))) {
        portsRef.current.setCustomValidity("")
    } else {
        portsRef.current.setCustomValidity(localization.getString("invalidPorts"))
        return
    }

    if (portCSVValidity(portsRef.current.value)) {
        portsRef.current.setCustomValidity(localization.getString("invalidPortRange"))
        return
    } else {
        portsRef.current.setCustomValidity("")
    }
}

interface DestinationTypeToggleProps {
    policyDestination: L4RuleExtended
    onAccessTypeChange: (type: AccessType) => void
    disabled: boolean
    className: string
    accessType: AccessType
}

function DestinationTypeToggle(props: DestinationTypeToggleProps): JSX.Element {
    const localization = useServiceLocalization()

    const accessTypeItems: ToggleButtonItem[] = [
        {
            label: localization.getString("ipRangesIps"),
            value: AccessType.IP_RANGES,
            selected: props.accessType === AccessType.IP_RANGES,
            onClick: () => props.onAccessTypeChange(AccessType.IP_RANGES),
        },
        {
            label: localization.getString("fqdns"),
            value: AccessType.FQDNS,
            selected: props.accessType === AccessType.FQDNS,
            onClick: () => props.onAccessTypeChange(AccessType.FQDNS),
        },
    ]

    return (
        <div className={props.className}>
            <ToggleButton disabled={props.disabled} items={accessTypeItems} />
        </div>
    )
}

import React from "react"
import { CollapsibleCard } from "../../../components/collapsible-card/CollapsibleCard.component"
import styles from "./PolicyDestinationCard.module.scss"

import { PolicyDestinationList } from "./PolicyDestinationList.component"
import { L4RuleExtended } from "../../../v3/services/Policy.service"

export interface Props {
    policyDestinations: L4RuleExtended[]
    disabled?: boolean
    disableDelete?: boolean
    onDestinationsChange: (destination: L4RuleExtended[]) => void
    id: string
    title: string
    description?: string
    defaultOpen: boolean
}

export function PolicyDestinationCard(props: Props) {
    const [isOpen, setIsOpen] = React.useState(props.defaultOpen)
    return (
        <CollapsibleCard
            id={props.id}
            title={props.title}
            solidBorder
            isOpen={isOpen}
            onOpen={setIsOpen}
        >
            <>
                <p className={styles.description}>{props.description}</p>

                <PolicyDestinationList
                    policyDestinations={props.policyDestinations}
                    disabled={props.disabled}
                    onDestinationsChange={props.onDestinationsChange}
                    enableRequiredCheck={props.defaultOpen}
                    disableDelete={props.disableDelete || false}
                />
            </>
        </CollapsibleCard>
    )
}

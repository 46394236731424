import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import {
    OrgApi,
    OrgInfoReq,
    OrgInfoRes,
    LicenseInformationRes,
    ProvisionStatusRes,
    StatusRes,
    TypeRes,
} from "../api/Org.api"
import { Edition } from "./shared/Edition"
import { OrgInfoBase, LicenseInformation, Status, mapLicenseInformation } from "./Org.service"
import { useEffect, useState } from "react"
import { SelectItem } from "../../pre-v3/utils/SelectValue.util"
import { useServiceLocalization } from "../../pre-v3/services"

export function useGetOrgs(disabled?: boolean) {
    const orgApi = new OrgApi()

    return useQuery<SuperAdminOrgInfo[], string>({
        queryKey: ["superAdminService.getOrgs"],
        queryFn: async () => {
            const res = await orgApi.getOrgs()
            return res.map((r) => mapOrgResToOrg(r))
        },
        enabled: !disabled,
    })
}

export function useGetOrgById(id: string) {
    const orgApi = new OrgApi()

    return useQuery<SuperAdminOrgInfo, string>({
        queryKey: ["superAdminService.getOrg", id],
        queryFn: async () => {
            const res = await orgApi.getOrgById(id)
            const license = (await orgApi.superAdminGetOrgLicenseById(id)) ?? undefined
            return mapOrgResToOrg(res, license)
        },
        enabled: !!id,
    })
}

export function useUpdateOrgArchivalDate(options?: QueryOptions<void, string, ArchiveAtParams>) {
    const orgApi = new OrgApi()
    const queryClient = useQueryClient()

    return useMutation<void, string, ArchiveAtParams>({
        ...options,
        mutationFn: (params) => orgApi.updateOrgArchivalDate(params.orgId, params.archiveAt),
        onSuccess: () => {
            queryClient.removeQueries(["superAdminService.getOrg"])
        },
    })
}

export function useEditOrg(options?: QueryOptions<void, string, AddEditOrgInfo>) {
    const orgApi = new OrgApi()
    const queryClient = useQueryClient()

    return useMutation<void, string, AddEditOrgInfo>({
        ...options,
        mutationFn: async (orgInfo) => orgApi.editOrg(mapAddEditOrgToAddEditOrgReq(orgInfo)),
        onSuccess: (...args) => {
            queryClient.removeQueries(["superAdminService.getOrgs"])
            queryClient.removeQueries(["superAdminService.getOrg"])
            options?.onSuccess?.(...args)
        },
    })
}
export const NOT_ASSIGNED_VALUE = "noAssignment"
export function useGetAssignMspOptions(isMsp?: boolean) {
    const disabled = isMsp ?? true
    const { data: orgs, isFetching, ...otherProps } = useGetOrgs(disabled)
    const [options, setOptions] = useState<SelectItem[]>()

    const localization = useServiceLocalization()

    useEffect(() => {
        if (orgs) {
            const newOptions = orgs
                .filter((orgs) => orgs.isMspOrg)
                .map((org) => ({ value: org.id, displayName: org.orgName }))

            setOptions([
                { value: NOT_ASSIGNED_VALUE, displayName: localization.getString("noAssignment") },
                ...newOptions,
            ])
        }
    }, [orgs])

    return { ...otherProps, data: options, isLoading: isFetching }
}

export function useAttachMspOrg() {
    const orgApi = new OrgApi()
    const queryClient = useQueryClient()

    return useMutation<void, string, AttachOrgToMspArgs>({
        mutationFn: (data) =>
            orgApi.attachMspOrg({
                msp_org_id: data.mspId,
                child_org_id: data.childOrgId,
            }),
        onSuccess: (_, args) => {
            queryClient.invalidateQueries(["superAdminService.getOrgs"])
            queryClient.invalidateQueries(["superAdminService.getOrg", args.childOrgId])
        },
    })
}

export function useDetachMspOrg() {
    const orgApi = new OrgApi()
    const queryClient = useQueryClient()

    return useMutation<void, string, AttachOrgToMspArgs>({
        mutationFn: (data) =>
            orgApi.detachMspOrg({
                msp_org_id: data.mspId,
                child_org_id: data.childOrgId,
            }),
        onSuccess: (_, args) => {
            queryClient.invalidateQueries(["superAdminService.getOrgs"])
            queryClient.invalidateQueries(["superAdminService.getOrg", args.childOrgId])
        },
    })
}

function mapOrgResToOrg(
    org: OrgInfoRes,
    licenseInformation?: LicenseInformationRes
): SuperAdminOrgInfo {
    const {
        access_tier,
        shield,
        user_pool,
        org_data,
        registered_domain,
        connector,
        invite_code,
        user_pool_domain,
        user,
        default_groups_user,
        user_pool_client,
        default_groups_user_pool,
        update_user_pool,
        invite_admin_user,
        private_edge_shield,
        ...otherStatusRes
    } = org.provision_status

    return {
        createdAt: DateUtil.convertLargeTimestamp(org.created_at),
        createdBy: org.created_by,
        customerID: org.customer_id,
        ...getLicenseEdition(org, licenseInformation),
        globalEdge: org.global_edge,
        id: org.org_id,
        lastUpdatedAt: DateUtil.convertLargeTimestamp(org.last_updated_at),
        lastUpdatedBy: org.last_updated_by,
        orgName: org.org_name,
        firstName: org.first_name,
        lastName: org.last_name,
        email: org.email,
        privateEdge: org.private_edge,
        isAiAssistedAdminSearchEnabled: org.is_ai_assist_enabled,
        isDnsFilterEnabled: org.is_dns_filter_enabled,
        // @cspell:ignore appdiscovery
        isAppDiscoveryEnabled: org.is_appdiscovery_enabled,
        // @cspell:ignore mysonicwall
        isSonicWallProvisioned: org.is_mysonicwall,
        status: org.status ? statusMap[org.status] : "unknown",
        type: orgTypeMapRes[org.type],
        archived: org.archived,
        isBanyanIdp: org.banyan_idp,
        isMspOrg: org.is_msp_org,
        parentOrgId: org.parent_org_id || undefined,
        provisionStatus: {
            accessTier: access_tier && provisionStatusMap[access_tier],
            shield: shield && provisionStatusMap[shield],
            userPool: user_pool && provisionStatusMap[user_pool],
            orgData: org_data && provisionStatusMap[org_data],
            registeredDomain: registered_domain && provisionStatusMap[registered_domain],
            connector: connector && provisionStatusMap[connector],
            inviteCode: invite_code && provisionStatusMap[invite_code],
            userPoolDomain: user_pool_domain && provisionStatusMap[user_pool_domain],
            user: user && provisionStatusMap[user],
            defaultGroupsUser: default_groups_user && provisionStatusMap[default_groups_user],
            userPoolClient: user_pool_client && provisionStatusMap[user_pool_client],
            defaultGroupsUserPool:
                default_groups_user_pool && provisionStatusMap[default_groups_user_pool],
            updateUserPool: update_user_pool && provisionStatusMap[update_user_pool],
            inviteAdminUser: invite_admin_user && provisionStatusMap[invite_admin_user],
            privateEdgeShield: private_edge_shield && provisionStatusMap[private_edge_shield],
        },
        internalOwner: org.internal_owner || "",
        archiveAt: org.archive_at || 0,
        ...mapOtherStatusRes(otherStatusRes),
    }
}
function getLicenseEdition(
    org: OrgInfoRes,
    license?: LicenseInformationRes
):
    | Pick<SuperAdminOrgInfoWithLicense, "licenseType" | "license" | "edition">
    | Pick<SuperAdminOrgInfoWithEdition, "licenseType" | "license" | "edition"> {
    if (license)
        return {
            licenseType: "license",
            license: mapLicenseInformation(license),
            edition: undefined,
        }

    return {
        licenseType: "edition",
        license: undefined,
        edition: editionMap[org.edition],
    }
}

function mapOtherStatusRes(
    otherStatusRes: Record<string, ProvisionStatusRes | undefined>
): Pick<SuperAdminOrgInfo, "accessTierComponents" | "unknownComponents"> {
    return Object.entries(otherStatusRes).reduce<
        Pick<SuperAdminOrgInfo, "accessTierComponents" | "unknownComponents">
    >((acc, [component, status]) => {
        if (!status) return acc

        const provisionStatus = provisionStatusMap[status]

        const [accessTier, region, ...rest] = component.split("access_tier-")

        if (accessTier === "" && rest.length <= 0) {
            return {
                ...acc,
                accessTierComponents: {
                    ...acc.accessTierComponents,
                    [region]: provisionStatus,
                },
            }
        }

        return {
            ...acc,
            unknownComponents: { ...acc.unknownComponents, [component]: provisionStatus },
        }
    }, {})
}

function mapAddEditOrgToAddEditOrgReq(org: AddEditOrgInfo): OrgInfoReq {
    return {
        org_name: org.orgName,
        edition: editionMapReq[org.edition],
        customer_id: org.customerID,
        global_edge: org.globalEdge,
        private_edge: org.privateEdge,
        is_ai_assist_enabled: org.isAiAssistedAdminSearchEnabled,
        is_appdiscovery_enabled: org.isAppDiscoveryEnabled,
        type: orgTypeMapReq[org.type],
        archived: org.archived,
        first_name: org.firstName,
        last_name: org.lastName,
        email: org.email,
        banyan_idp: org.isBanyanIdp,
        is_msp_org: org.isMspOrg,
        is_dns_filter_enabled: org.isDnsFilterEnabled || false,
        is_urlfiltering_enabled: org.isUrlFilterEnable || false,
        internal_owner: org.internalOwner || null,
        archive_at: org.archiveAt || null,
    }
}

export interface OrgArchiveData {
    archiveAt: number
}

const editionMap: Record<OrgInfoRes["edition"], Edition> = {
    Team: Edition.TEAM,
    Enterprise: Edition.ENTERPRISE,
    Unlimited: Edition.UNLIMITED,
}

const statusMap: Record<StatusRes, Status> = {
    Success: "success",
    Failed: "error",
    PartialSuccess: "partialSuccess",
    InProgress: "inProgress",
}

const orgTypeMapReq: Record<OrgType, TypeRes> = {
    Production: "Production",
    Trial: "Trial",
    Testing: "Testing",
    Staging: "Staging",
    Internal: "Internal",
    "Internal Persistent": "InternalPersistent",
    "Internal Temporary": "InternalTemporary",
}

const orgTypeMapRes: Record<TypeRes, OrgType> = {
    Production: "Production",
    Trial: "Trial",
    Testing: "Testing",
    Staging: "Staging",
    Internal: "Internal",
    InternalPersistent: "Internal Persistent",
    InternalTemporary: "Internal Temporary",
}

export type OrgType =
    | "Production"
    | "Trial"
    | "Staging"
    | "Testing"
    | "Internal"
    | "Internal Temporary"
    | "Internal Persistent"

export enum ProvisionStatus {
    Success = "success",
    Failed = "error",
    Archived = "archived",
    Pending = "pending",
    Deleted = "deleted",
}

const provisionStatusMap: Record<ProvisionStatusRes, ProvisionStatus> = {
    Success: ProvisionStatus.Success,
    Failed: ProvisionStatus.Failed,
    Archived: ProvisionStatus.Archived,
    Pending: ProvisionStatus.Pending,
    Deleted: ProvisionStatus.Deleted,
}

const editionMapReq: Record<Edition, OrgInfoRes["edition"]> = {
    team: "Team",
    enterprise: "Enterprise",
    unlimited: "Unlimited",
}

type ProvisionedComponent =
    | "accessTier"
    | "shield"
    | "userPool"
    | "orgData"
    | "registeredDomain"
    | "connector"
    | "inviteCode"
    | "userPoolDomain"
    | "user"
    | "defaultGroupsUser"
    | "userPoolClient"
    | "defaultGroupsUserPool"
    | "updateUserPool"
    | "inviteAdminUser"
    | "privateEdgeShield"

export interface SuperAdminOrgInfoBase extends Omit<OrgInfoBase, "orgType"> {
    orgName: string
    firstName: string
    lastName: string
    email: string
    status?: Status
    type:
        | "Production"
        | "Trial"
        | "Staging"
        | "Testing"
        | "Internal"
        | "Internal Temporary"
        | "Internal Persistent"
    customerID: string
    globalEdge: boolean
    privateEdge: boolean
    isAppDiscoveryEnabled: boolean
    isSonicWallProvisioned: boolean
    createdBy?: string
    archived: boolean
    provisionStatus?: Record<ProvisionedComponent, ProvisionStatus | undefined>
    accessTierComponents?: Record<string, ProvisionStatus>
    unknownComponents?: Record<string, ProvisionStatus>
    isDnsFilterEnabled: boolean
    parentOrgId?: string
    internalOwner?: string
    archiveAt?: number
}

interface SuperAdminOrgInfoWithEdition extends SuperAdminOrgInfoBase {
    licenseType: "edition"
    edition: Edition
    license: undefined
}
interface SuperAdminOrgInfoWithLicense extends SuperAdminOrgInfoBase {
    licenseType: "license"
    license: LicenseInformation
    edition: undefined
}

export type SuperAdminOrgInfo = SuperAdminOrgInfoWithEdition | SuperAdminOrgInfoWithLicense

export interface AddEditOrgInfo
    extends Required<
        Pick<
            SuperAdminOrgInfoWithEdition,
            | "orgName"
            | "edition"
            | "globalEdge"
            | "privateEdge"
            | "isAiAssistedAdminSearchEnabled"
            | "isAppDiscoveryEnabled"
            | "type"
            | "customerID"
            | "archived"
        >
    > {
    firstName: string
    lastName: string
    email: string
    isBanyanIdp?: boolean
    isMspOrg: boolean
    isDnsFilterEnabled?: boolean
    isUrlFilterEnable?: boolean
    internalOwner?: string
    archiveAt?: number
}

interface ArchiveAtParams {
    orgId: string
    archiveAt: number
}

interface AttachOrgToMspArgs {
    mspId: string
    childOrgId: string
}

import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { PolicyStatus, PolicyType, useEditPolicy } from "../../../../v3/services/Policy.service"
import styles from "./TunnelPolicyEdit.module.scss"
import { Form } from "../../../../components/form/Form.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { ErrorToast, ToastApi } from "../../../../components/toast/Toast.components"
import { GeneralInfo, TunnelPolicyForm } from "../shared/TunnelPolicyForm.component"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import {
    OverviewTopContainer,
    StatusType,
} from "../../../../v3/components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { formatToLocalDateStr } from "../../../../utils/Date.utils"
import { PolicyExtendedWithId } from "../../shared/PolicyAccess.type"

interface Props {
    isEditMode?: boolean
    policy: PolicyExtendedWithId
    refetch?: () => void
}

export function TunnelPolicyFormWrapper(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const locale = localization.getLocale()
    const history = useHistory()
    const params = useParams<{ id: string }>()
    const toastRef = React.useRef<ToastApi>(null)

    const [editablePolicy, setEditablePolicy] = React.useState(props.policy)
    const saveButtonRef = React.useRef<HTMLButtonElement & HTMLAnchorElement>(null)

    const onGeneralInfoChange = (generalInfo: GeneralInfo) => {
        setEditablePolicy((prev) => ({
            ...prev,
            name: generalInfo.name,
            description: generalInfo.description || "",
        }))
    }

    const onPolicyChange = (updatedPolicy: PolicyExtendedWithId) => {
        setEditablePolicy(updatedPolicy)
    }

    const { mutate: editPolicy, isLoading: isUpdatingTunnelPolicy } = useEditPolicy(
        props.policy.id,
        {
            onSuccess: () => {
                history.push(
                    formatRoutePath(ROUTE.ACCESS_POLICIES_TUNNEL_POLICY_DETAILS, {
                        id: encodeID(props.policy.id),
                    })
                )
            },
            onError: (error) =>
                typeof error === "string"
                    ? toastRef.current?.openToast(error)
                    : console.error(error),
        }
    )

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        editPolicy({
            metadata: {
                name: editablePolicy.name,
                description: editablePolicy.description,
            },
            policyType: PolicyType.TUNNEL,
            spec: editablePolicy.spec,
        })
    }

    function onCancel() {
        props.refetch?.()
        history.push(
            formatRoutePath(ROUTE.ACCESS_POLICIES_TUNNEL_POLICY_DETAILS, {
                id: params.id,
            })
        )
    }

    return (
        <React.Fragment>
            <Form
                aria-label={localization.getString(
                    "editSomethingWithLabel",
                    props.policy.name,
                    localization.getString("tunnelPolicy")
                )}
                onSubmit={onSubmit}
                className={styles.form}
            >
                {!props.isEditMode && (
                    <OverviewTopContainer
                        statusType={statusMap[props.policy.status]}
                        statusLabel={localization.getString(labelMap[props.policy.status])}
                        statusItems={[
                            {
                                label: localization.getString("lastUpdated"),
                                value: formatToLocalDateStr(
                                    new Date(props.policy.lastUpdatedAt),
                                    locale
                                ),
                            },
                        ]}
                        listItems={[
                            {
                                label: localization.getString("version"),
                                value: props.policy.version,
                            },
                            {
                                label: localization.getString("createdAt"),
                                value: formatToLocalDateStr(
                                    new Date(props.policy.createdAt),
                                    locale
                                ),
                            },
                            {
                                label: localization.getString("createdBy"),
                                value: props.policy.createdBy,
                            },
                            {
                                label: localization.getString("lastUpdatedBy"),
                                value: props.policy.lastUpdatedBy,
                            },
                        ]}
                    />
                )}

                <TunnelPolicyForm
                    policy={props.isEditMode ? editablePolicy : props.policy}
                    onGeneralInfoChange={onGeneralInfoChange}
                    onPolicyChange={onPolicyChange}
                    isEditMode={props.isEditMode}
                    disabled={!props.isEditMode}
                />
                {props.isEditMode && (
                    <div className={styles.actionButtons}>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onCancel}
                        >
                            {localization.getString("cancel")}
                        </Button>

                        <Button
                            ref={saveButtonRef}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                            loading={isUpdatingTunnelPolicy}
                        >
                            {localization.getString("save")}
                        </Button>
                    </div>
                )}
            </Form>
            <ErrorToast ref={toastRef} />
        </React.Fragment>
    )
}

export const statusMap: Record<PolicyStatus, StatusType> = {
    [PolicyStatus.INACTIVE]: "disabled",
    [PolicyStatus.ACTIVE]: "success",
}

export const labelMap: Record<PolicyStatus, LanguageKey> = {
    [PolicyStatus.INACTIVE]: "inactive",
    [PolicyStatus.ACTIVE]: "active",
}

export const iconMap: Record<PolicyStatus, IconType> = {
    [PolicyStatus.INACTIVE]: IconType.PAUSE_CIRCLE,
    [PolicyStatus.ACTIVE]: IconType.CHECK_SOLID,
}

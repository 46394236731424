import { BaseApi, PaginationOptions, PaginatedResponse } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("HostedServiceApi")
export class HostedServiceApi extends BaseApi {
    public getHostedServices(): Promise<HostedServiceRes[]> {
        return this.get(`/api/v1/registered_services`, {
            default: this.localization.getString("failedToLoadServices"),
        })
    }

    public insertHostedService(service: HostedServiceReq): Promise<HostedServiceRes> {
        return this.post("/api/v1/insert_registered_service", service, {
            default: this.localization.getString("failedToCreateService"),
        })
    }

    public deleteHostedService(id: string): Promise<void> {
        return this.delete(
            `/api/v1/delete_registered_service?ServiceID=${encodeURIComponent(id)}`,
            {},
            {
                default: this.localization.getString("failedToDeleteService"),
            }
        )
    }

    public enableHostedService(id: string): Promise<void> {
        return this.post(
            `/api/v1/enable_registered_service?ServiceID=${encodeURIComponent(id)}`,
            {},
            {
                default: this.localization.getString("failedToUpdateServiceStatus"),
            }
        )
    }

    public disableHostedService(id: string): Promise<void> {
        return this.post(
            `/api/v1/disable_registered_service?ServiceID=${encodeURIComponent(id)}`,
            {},
            {
                default: this.localization.getString("failedToUpdateServiceStatus"),
            }
        )
    }

    public getHostedServicesByAccessTierId(
        serviceId: string,
        params: PaginationOptions
    ): Promise<PaginatedResponse<"registered_services", HostedServiceRes>> {
        const urlParams: URLSearchParams = new URLSearchParams(params as any)
        const url = `/api/v2/access_tier/${serviceId}/registered_services?${urlParams.toString()}`
        return this.get(url, {
            default: this.localization.getString("failedToLoadServices"),
        })
    }
}

export interface HostedServiceRes {
    ServiceID: string
    ServiceSpec: string
    CreatedAt: number
    CreatedBy: string
    LastUpdatedAt: number
    LastUpdatedBy: string
    Enabled: "TRUE" | "FALSE"
    ServiceName: string
    AttachedPolicy: AttachedPolicy | null
}

export interface AttachedPolicy {
    AttachedAt: number
    AttachedBy: string
    PolicyID: string
    PolicyName: string
    PolicyStatus: boolean
}

export interface HostedServiceReq {
    apiVersion: "rbac.banyanops.com/v1"
    kind: "BanyanService"
    metadata: HostedServiceMetadataReq
    spec: HostedServiceSpecReq
    type: "origin"
}

export interface HostedServiceMetadataReq {
    cluster: string
    description: string
    name: string
    tags: {
        description_link: string
        domain: string
        icon: string
        port: string
        protocol: "https" | "tcp" // VERIFY
        template: "WEB_USER" | "TCP_USER"
        service_app_type: ServiceAppTypeReq
        user_facing: "true" | "false"

        registered_domain_id?: string // NEEDS BACKEND CHANGE

        ssh_chain_mode?: boolean
        ssh_service_type?: "TRUSTCERT" | "BOTH"
        ssh_host_directive?: string
        write_ssh_config?: boolean
    }
}

export type ServiceAppTypeReq = "WEB" | "SSH" | "RDP" | "K8S" | "GENERIC" | "DATABASE"

export interface HostedServiceSpecReq {
    attributes: {
        frontend_addresses: {
            cidr: string
            port: string
        }[]
        host_tag_selector: [
            {
                "com.banyanops.hosttag.site_name"?: string
                "com.banyanops.hosttag.access_tier_group"?: string
            },
        ]
        tls_sni: string[]
        disable_private_dns: boolean
    }
    backend: {
        allow_patterns?: {
            hostnames: string[]
            cidrs: string[]
        }[]
        connector_name: string
        dns_overrides: StringMap // map of frontend domain -> dns override
        http_connect: boolean
        target: {
            name: string
            port: string
            tls: boolean
            tls_insecure: boolean
            client_certificate: boolean
        }
        whitelist: string[] // VERIFY
    }
    cert_settings: {
        dns_names: string[]
        custom_tls_cert: {
            cert_file: string
            enabled: boolean
            key_file: string
        }
        letsencrypt: boolean
    }
    client_cidrs: string[] // VERIFY
    http_settings: {
        enabled: boolean
        exempted_paths: {
            enabled: boolean
            paths: string[] // Deprecated
            patterns: {
                hosts: {
                    origin_header: string[]
                    target: string[]
                }[]
                mandatory_headers: string[]
                methods: string[]
                paths: string[]
                source_cidrs: string[]
                template: "CORS" | "CIDR" | "PATH"
            }[]
        }
        custom_trust_cookie?: {
            path?: string
            same_site?: SameSiteReq
        }
        headers: StringMap
        http_health_check: {
            enabled: boolean
            from_address: string[]
            method: string
            path: string
            user_agent: string
        }
        oidc_settings: {
            api_path: string
            enabled: boolean
            post_auth_redirect_path: string
            service_domain_name: string
            suppress_device_trust_verification: boolean
        }
        token_loc: {
            authorization_header: boolean
            custom_header: string
            query_param: string
        }
    }
}

export type SameSiteReq = "" | "default" | "lax" | "none" | "strict"

import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import { ROUTE } from "../../routes"
import { OrgManagementList as OrgList } from "../../v3/views/org-management/list/OrgManagementList.view"
import { AddOrg } from "./add/AddOrg.page"
import { OrgDetails } from "./details/OrgDetails.page"
import { EditOrg } from "./edit/EditOrg.page"
import styles from "./MomOrgManagement.module.scss"

interface Props {
    canDeleteOrg: boolean
    canAccessSupportBundles: boolean
}

export function MomOrgManagement(props: Props): JSX.Element {
    return (
        <div className={styles.container}>
            <Switch>
                <Route path={ROUTE.ORG_MANAGEMENT_ADD} component={AddOrg} />
                <Route path={ROUTE.ORG_MANAGEMENT_EDIT} component={EditOrg} />
                <Route path={ROUTE.ORG_MANAGEMENT_DETAILS}>
                    <OrgDetails
                        canDeleteOrg={props.canDeleteOrg}
                        canAccessSupportBundles={props.canAccessSupportBundles}
                    />
                </Route>
                <Route path={ROUTE.ORG_MANAGEMENT} component={OrgList} />
                <Redirect to={ROUTE.ORG_MANAGEMENT} />
            </Switch>
        </div>
    )
}

import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons"
import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { PageBreak } from "../../../../pre-v3/components/page-break/PageBreak.component"
import ActionBarService, { IconType } from "../../../../pre-v3/services/ActionBar.service"
import { LinkService } from "../../../../pre-v3/services/link/Link.service"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { formatRoutePath, ROUTE } from "../../../../routes"
import { AppText } from "../../../components/app-text/AppText.component"
import { ErrorBanner, ErrorBanners } from "../../../components/banner/Banner.component"
import { Container } from "../../../components/container/Container.component"
import { Column, Grid, RowDragEndProps } from "../../../components/grid/Grid.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import { Link } from "../../../components/link/Link.component"
import { Loader } from "../../../components/loader/Loader.component"
import { OverviewTopContainer } from "../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { Status, StatusType } from "../../../components/status/Status.component"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import {
    ItpPolicy,
    SyncStatus,
    isExcludedDevicesPolicy,
    labelMap,
    useGetItpPolicySyncStatus,
    useGetItpPolicies,
    useReorderItpPolicies,
    useGetDnsReport,
} from "../../../services/ItpPolicy.service"
import { Step as ItpPolicyFormStep } from "../form/ItpPolicyForm.component"
import { DnsReport as DnsReportTab } from "./DnsReportTab.view"
import styles from "./ItpPolicyList.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import { SettingsTab } from "./SettingsTab.view"
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags.hook"

interface Props {
    canCreateItpPolicy: boolean
    canReorderItpPolicy: boolean
    canGenerateReport?: boolean
}

export function ItpPolicyList(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const actionBarService = new ActionBarService()
    const links = new LinkService()

    const [allowDrag, setAllowDrag] = React.useState(false)
    const [reorderData, setReorderData] = React.useState<ItpPolicy[]>([])
    const [step, setStep] = React.useState(ItpPolicyListStep.POLICIES)
    const { data: featureFlags, refetch: refetchFeatureFlags } = useFeatureFlags()

    const {
        isFetching: isFetchingItpPolicy,
        data: itpPolicyList,
        error: itpPoliciesError,
        isError: isItpPoliciesError,
        refetch: itpPoliciesRefetch,
    } = useGetItpPolicies()

    const {
        isFetching: isSyncStatusLoading,
        data: itpPolicySyncStatus,
        refetch: itpPolicySyncStatusRefetch,
    } = useGetItpPolicySyncStatus()

    const syncStatus: SyncStatus = itpPolicySyncStatus?.status || "Error"

    const {
        isLoading: isReorderLoading,
        error: reorderError,
        mutateAsync: reorder,
        reset: resetReorder,
    } = useReorderItpPolicies()
    const { refetch: refetchDnsReport } = useGetDnsReport()

    const itpPolicies = itpPolicyList?.itpPolicies ?? []

    const isLoading = isFetchingItpPolicy || isReorderLoading
    const itpPoliciesToDisplay = itpPolicyList
        ? allowDrag
            ? [itpPolicyList.excludedDevicesPolicy, ...reorderData]
            : [itpPolicyList.excludedDevicesPolicy, ...itpPolicies]
        : undefined

    const errors: React.ReactNode[] = [itpPoliciesError ? String(itpPoliciesError) : null]

    const columns = useColumns(allowDrag)

    const noDataToSort = !itpPolicies || itpPolicies.length < 2

    function onRefresh() {
        itpPoliciesRefetch()
        itpPolicySyncStatusRefetch()
        refetchDnsReport()
        refetchFeatureFlags()
    }

    const onToggleDrag = (itpPoliciesToReorder: ItpPolicy[], newAllowDrag: boolean) => {
        setAllowDrag(newAllowDrag)
        setReorderData(itpPoliciesToReorder)

        actionBarService.setTitle(
            localization.getString(newAllowDrag ? "prioritizeItpPolicies" : "itpPolicies")
        )
    }

    function onRowDragEnd(event: RowDragEndProps<ItpPolicy>) {
        const updatedOrder = updateOrder({
            itpPolicies: reorderData,
            fromIndex: event.node.rowIndex - 1,
            toIndex: event.overNode.rowIndex - 1,
        })

        setReorderData(updatedOrder)
    }

    function cancelReOrder() {
        onToggleDrag([], false)
        resetReorder()
    }

    async function saveReOrder() {
        await reorder(reorderData)
        onToggleDrag([], false)
    }

    function getStepLabel(step: ItpPolicyListStep) {
        return localization.getString(itpPolicyListStepLabels[step])
    }

    function getTabProps(currentStep: ItpPolicyListStep) {
        return {
            id: currentStep,
            label: getStepLabel(currentStep),
            ariaControls: itpPolicyListStepAriaControls[currentStep],
        }
    }

    function getSyncEtaInTimeSpan(time: number): string {
        const timeSpan = DateUtil.timeToTimeSpan(time)
        const syncEtaList: string[] = []
        const listFormatter = new Intl.ListFormat(localization.getLocale(), { type: "conjunction" })

        timeSpan.days && syncEtaList.push(localization.getPluralString("someDay", timeSpan.days))
        timeSpan.hours && syncEtaList.push(localization.getPluralString("someHr", timeSpan.hours))
        timeSpan.minutes &&
            syncEtaList.push(localization.getPluralString("someMin", timeSpan.minutes))
        !timeSpan.days &&
            !timeSpan.hours &&
            !timeSpan.minutes &&
            timeSpan.seconds &&
            syncEtaList.push(localization.getString("lessThanOneMin"))

        return syncEtaList.length > 0 ? listFormatter.format(syncEtaList) : "-"
    }

    const viewTabs = React.useMemo(
        () =>
            itpPolicyListSteps.map(getTabProps).filter((tab) => {
                if (
                    tab.id === ItpPolicyListStep.SETTINGS &&
                    !featureFlags?.adminConsole.canAccessItpSettings
                ) {
                    return false
                }
                return true
            }),
        [featureFlags?.adminConsole.canAccessItpSettings]
    )

    return (
        <Container as="section" fullWidth aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>
                    {localization.getString("internetThreatProtection")}
                </PageHeading>
                <div className={styles.actionButtons}>
                    {props.canReorderItpPolicy && step === ItpPolicyListStep.POLICIES && (
                        <Tooltip title={localization.getString("reorder")}>
                            <Button
                                icon={IconType.SORT}
                                onClick={() => onToggleDrag(itpPolicies ?? [], !allowDrag)}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                disabled={isLoading || isItpPoliciesError || noDataToSort}
                                aria-label={localization.getString("reorder")}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <Loader isLoading={isLoading} center medium>
                <div className={styles.syncStatusContainer}>
                    <OverviewTopContainer
                        statusType="unknown"
                        statusContainerClassName={styles.statusContainer}
                        hideStatusType
                        statusItems={[
                            {
                                label: localization.getString("status"),
                                value: (
                                    <Loader isLoading={isSyncStatusLoading}>
                                        {StatusCellRenderer(syncStatus)}
                                    </Loader>
                                ),
                            },
                            {
                                label: localization.getString("syncEta"),
                                value: (
                                    <Loader isLoading={isSyncStatusLoading}>
                                        {getSyncEtaInTimeSpan(
                                            DateUtil.timeUntil(itpPolicySyncStatus?.eta ?? 0)
                                        )}
                                    </Loader>
                                ),
                            },
                        ]}
                    />
                    <AppText
                        className={styles.statusInfo}
                        ls={{
                            key: "deviceCountDoesNotUpdateInRealTimeDesc",
                            replaceVals: [links.getLink("internetThreatProtectionDoc")],
                        }}
                    />
                </div>
                {allowDrag && (
                    <AppText
                        className={styles.description}
                        ls={"dragItpPoliciesToDesiredPriorityOrder"}
                    />
                )}
                {!allowDrag && (
                    <div className={styles.tabBarContainer}>
                        <TabBar
                            className={styles.tabBar}
                            tabs={viewTabs}
                            selectedTabId={step}
                            onChange={setStep}
                        />
                        {props.canCreateItpPolicy && step === ItpPolicyListStep.POLICIES && (
                            <Button
                                buttonType={ButtonType.PRIMARY}
                                className={styles.createButton}
                                asElement={ButtonElement.LINK}
                                to={ROUTE.INTERNET_THREAT_PROTECTION_ADD}
                                icon={IconType.PLUS}
                                aria-label={localization.getString("createPolicy")}
                            >
                                {localization.getString("createPolicy")}
                            </Button>
                        )}
                    </div>
                )}
                {step === ItpPolicyListStep.REPORTING && (
                    <DnsReportTab canGenerateReport={props.canGenerateReport} />
                )}
                {step === ItpPolicyListStep.POLICIES && (
                    <>
                        <ErrorBanners errors={errors} />
                        {!isItpPoliciesError && (
                            <Grid
                                data={itpPoliciesToDisplay}
                                columns={columns}
                                onRowDragEnd={onRowDragEnd}
                            />
                        )}
                        {allowDrag && (
                            <>
                                {reorderError && (
                                    <ErrorBanner className={styles.errorBanner}>
                                        {String(reorderError)}
                                    </ErrorBanner>
                                )}
                                <PageBreak className={styles.pageBreak} />
                                <div className={styles.buttonGroup}>
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        onClick={cancelReOrder}
                                    >
                                        {localization.getString("cancel")}
                                    </Button>
                                    <Button
                                        onClick={saveReOrder}
                                        loading={isReorderLoading}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.PRIMARY}
                                    >
                                        {localization.getString("save")}
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                )}
                {step === ItpPolicyListStep.SETTINGS &&
                    featureFlags?.adminConsole.canAccessItpSettings && (
                        <SettingsTab
                            isUrlFilteringEnabled={featureFlags?.adminConsole.isUrlFilteringEnabled}
                            canUpdateItpSettings={featureFlags?.adminConsole.canUpdateItpSettings}
                        />
                    )}
            </Loader>
        </Container>
    )
}

enum Id {
    HEADING = "heading",
}

function useColumns(allowDrag: boolean): Column<ItpPolicy>[] {
    const localization = useServiceLocalization()

    return React.useMemo((): Column<ItpPolicy>[] => {
        return [
            {
                id: "priority",
                name: localization.getString("priority"),
                cellRenderer: getPriority,
                getTooltipValue: getPriority,
                isRowDrag: (itpPolicy) => !isExcludedDevicesPolicy(itpPolicy) && allowDrag,
            },
            {
                id: "name",
                name: localization.getString("name"),
                cellRenderer: (data: ItpPolicy) => <NameCell itpPolicy={data} />,
                getTooltipValue: "name",
            },
            {
                id: "lastUpdated",
                name: localization.getString("lastUpdated"),
                getTooltipValue: getLastUpdated,
                cellRenderer: getLastUpdated,
            },
            {
                id: "devices",
                name: localization.getString("devices"),
                getTooltipValue: (data: ItpPolicy) =>
                    localization.getPluralString("countDevices", data.deviceCount),
                cellRenderer: (data: ItpPolicy) => (
                    <Link
                        to={`${formatRoutePath(ROUTE.INTERNET_THREAT_PROTECTION_DETAILS, {
                            id: encodeID(data.id),
                        })}?step=${ItpPolicyFormStep.DEVICES}`}
                        aria-label={localization.getPluralString("countDevices", data.deviceCount)}
                    >
                        {data.deviceCount}
                    </Link>
                ),
            },
        ]
    }, [allowDrag, localization])
}

interface UpdateOrderProps {
    fromIndex: number
    toIndex: number
    itpPolicies: ItpPolicy[]
}

function updateOrder(props: UpdateOrderProps): ItpPolicy[] {
    const { fromIndex, toIndex, itpPolicies } = props

    const draggedItem = itpPolicies.find((_, rowIndex) => rowIndex === fromIndex)
    const draggedItemRemoved = itpPolicies.filter((_, rowIndex) => {
        return rowIndex !== fromIndex
    })

    const beforeRow = draggedItemRemoved.slice(0, toIndex)
    const afterRow = draggedItemRemoved.slice(toIndex, draggedItemRemoved.length)

    return [...beforeRow, draggedItem!, ...afterRow].map((protectionProfile, index) => {
        return { ...protectionProfile, priority: index + 1 }
    })
}

function getPriority(itpPolicy: ItpPolicy): string | number {
    return isExcludedDevicesPolicy(itpPolicy) ? "-" : itpPolicy.priority
}

interface NameCellProps {
    itpPolicy: ItpPolicy
}

function NameCell(props: NameCellProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <RowTitle
            title={props.itpPolicy.name}
            description={
                isExcludedDevicesPolicy(props.itpPolicy)
                    ? localization.getString("excludedDevicesPolicyDescription")
                    : undefined
            }
            route={formatRoutePath(ROUTE.INTERNET_THREAT_PROTECTION_DETAILS, {
                id: encodeID(props.itpPolicy.id),
            })}
        />
    )
}

function getLastUpdated(itpPolicy: ItpPolicy): string {
    return DateUtil.format(itpPolicy.lastUpdatedAt)
}

export enum ItpPolicyListStep {
    POLICIES = "policies",
    REPORTING = "reporting",
    SETTINGS = "settings",
}

export const itpPolicyListStepAriaControls: Record<ItpPolicyListStep, string> = {
    [ItpPolicyListStep.POLICIES]: "tab-panel-policies",
    [ItpPolicyListStep.REPORTING]: "tab-panel-content-reporting",
    [ItpPolicyListStep.SETTINGS]: "tab-panel-content-settings",
}

export const itpPolicyListStepLabels: Record<ItpPolicyListStep, LanguageKey> = {
    [ItpPolicyListStep.POLICIES]: "policies",
    [ItpPolicyListStep.REPORTING]: "reporting",
    [ItpPolicyListStep.SETTINGS]: "settings",
}

const itpPolicyListSteps = Object.values(ItpPolicyListStep)

function StatusCellRenderer(status: SyncStatus): React.ReactNode {
    const ls = useServiceLocalization()
    if (!status) {
        return "-"
    }

    let icon
    if (status === "InProgress") {
        icon = faSyncAlt
    }

    return (
        <Status
            type={statusMap[status]}
            label={ls.getString(labelMap[status])}
            icon={icon}
            iconClassName={styles.statusIcon}
        />
    )
}

const statusMap: Record<SyncStatus, StatusType> = {
    Completed: "success",
    InProgress: "disabled",
    Error: "error",
}

import { L4RuleExtended, PolicyAccessExtended } from "../../../v3/services/Policy.service"
import { v4 as uuidV4 } from "uuid"

export const emptyDestination: L4RuleExtended = {
    id: uuidV4(),
    ipRanges: "",
    fqdnList: "",
    protocols: [],
    ports: [],
    description: "",
}

export const emptyPolicyAccess: PolicyAccessExtended = {
    name: "",
    description: "",
    roles: [],
    rules: {
        l4_access: {
            allow: [emptyDestination],
            deny: [emptyDestination],
        },
        conditions: {},
    },
}

import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UserOrgDetailsRes } from "./shared/EndUserDevice.api"

@Singleton("DeviceConfigApi")
export class DeviceConfigApi extends BaseApi {
    public getUserOrgDetails(): Promise<UserOrgDetailsRes> {
        return this.get("/api/v1/user_org_details", {
            default: this.localization.getString("errorCouldNotFetchUserOrgDetails"),
        })
    }

    public updateDeviceConfig(deviceConfig: DeviceConfigReq): Promise<void> {
        return this.postForm("/api/v1/update_org", deviceConfig, {
            default: this.localization.getString("errorFailedToUpdateDeviceProviderConfig"),
        })
    }

    public deleteDeviceProviderConfig(idpName: string): Promise<void> {
        return this.postForm(
            "/api/v1/update_org",
            {
                DeviceRegIDPName: idpName,
            },
            {
                default: this.localization.getString("errorFailedToDeleteDeviceConfig"),
            }
        )
    }
}

export interface DeviceConfigRes {
    IssuerURL?: string
    ClientID?: string
    ClientSecret?: string
    RedirectURL?: string
    AccessKeyId?: string
    SecretAccessKey?: string
    UserPoolId?: string
    UserPoolDomain?: string
    SSOURL?: string
    CAData?: string
    UsernameAttr?: string
    EmailAttr?: string
    GroupsAttr?: string
    GroupsDelim?: string
    EntityIssuer?: string
    PWLessConfig?: string
    DeviceRegClientID?: string
    DeviceRegClientSecret?: string
    MetadataURL?: string
}

export interface DeviceConfigReq {
    DeviceRegIDPName?: string
    DeviceRegIDPProtocol?: "SAML" | "OIDC"
    DeviceRegConfig?: string
    DeviceRegIDPMetadataURL?: string
}

import React from "react"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import styles from "./AssignmentCard.module.scss"
import { Policy, PolicyAttachment } from "../../../v3/services/Policy.service"
import { FormRow } from "../../../components/form/FormRow.component"
import { AppText } from "../../../v3/components/app-text/AppText.component"
import {
    MenuSelect,
    MenuSelectOption,
} from "../../../v3/components/menu-select/MenuSelect.component"
import { faLink } from "@fortawesome/pro-regular-svg-icons"
import { Switch } from "../../../v3/components/switch/Switch.component"

import { ToggleButton, ToggleButtonItem } from "../../../v3/components/toggle-button/ToggleButton"
import { useServiceLinks } from "../../../pre-v3/services/link/Link.service"
import { ServiceTunnelDetail } from "../../../v3/services/ServiceTunnelV2.service"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { SummaryTable } from "./SummaryTable.component"
import { MultiInput } from "../../../components/multi-input/MultiInput.component"

export interface Props {
    serviceTunnel: ServiceTunnelDetail
    policies: Policy[]
    disabled?: boolean
    onPolicyAttachmentChange?: (policyAttachment: PolicyAttachment | undefined) => void
    onAutoStartOnLoginChange?: (autorun: boolean) => void
    onUserControlStartOnLoginChange?: (isAutorunLocked: boolean) => void
    onPrivateSearchDomainsChange?: (searchDomains: string[]) => void
}

export interface AccessPermissionsFormValues {
    policyAttachment?: PolicyAttachment
}

export enum PolicyMode {
    ENFORCING = "enforcing",
    PERMISSIVE = "permissive",
}

export function AssignmentCard(props: Props) {
    const { disabled = false } = props

    const localization = useServiceLocalization()
    const linkService = useServiceLinks()

    const {
        policyAttachment,
        autorun: startOnLogin,
        isAutorunLocked: userControlStartOnLogin,
    } = props.serviceTunnel

    const options: MenuSelectOption[] = React.useMemo(() => {
        return props.policies
            ? props.policies
                  .map((p) => {
                      return { label: p.name, value: p.id }
                  })
                  .sort((a, b) => (a.label > b.label ? 1 : -1))
            : []
    }, [props.policies])

    function onSelectPolicy(policyId: string) {
        const selectedPolicy = props.policies.find((policy) => policy.id === policyId)

        const policyAttachment = selectedPolicy && createPolicyAttachment(selectedPolicy)

        props.onPolicyAttachmentChange?.(policyAttachment)
    }

    const onAutoStartOnLoginChange = (runOnLogin: boolean) => {
        props.onAutoStartOnLoginChange?.(runOnLogin)
        if (!runOnLogin) {
            props.onUserControlStartOnLoginChange?.(false)
        }
    }

    return (
        <div className={styles.card}>
            <div className={styles.header}>
                <div className={styles.title}>{localization.getString("assignmentSettings")}</div>
            </div>
            <div className={styles.content}>
                <FormRow
                    label={localization.getString("attachAPolicy")}
                    description={<AppText ls="selectAPolicyToAttach" />}
                >
                    <MenuSelect
                        icon={faLink}
                        value={policyAttachment?.policyId ?? ""}
                        options={
                            disabled
                                ? [
                                      {
                                          label: policyAttachment?.policyName || "",
                                          value: policyAttachment?.policyId || "",
                                      },
                                  ]
                                : options
                        }
                        label={localization.getString("attachAPolicy")}
                        onSelect={onSelectPolicy}
                        disabled={disabled}
                    />
                </FormRow>

                {policyAttachment && (
                    <PolicyModeToggle
                        policyAttachment={policyAttachment}
                        onPolicyAttachmentChange={props.onPolicyAttachmentChange}
                        disabled={disabled}
                    />
                )}
                <FormRow
                    label={localization.getString("privateSearchDomains")}
                    description={localization.getString("privateSearchDomainsDesc")}
                >
                    <MultiInput
                        values={props.serviceTunnel.dnsSearchDomains}
                        onChange={props.onPrivateSearchDomainsChange}
                        disabled={disabled}
                    />
                </FormRow>

                <FormRow
                    label={localization.getString("connectOnLogin")}
                    description={localization.getString("connectOnLoginServiceTunnelDescription")}
                >
                    <Switch
                        disabled={disabled}
                        value={startOnLogin}
                        onChange={onAutoStartOnLoginChange}
                    />
                </FormRow>
                {startOnLogin && (
                    <FormRow
                        label={localization.getString(
                            "preventUsersFromChoosingServiceTunnelToConnectOnLogin"
                        )}
                        description={localization.getString(
                            "preventUsersFromChoosingServiceTunnelToConnectOnLoginDesc"
                        )}
                    >
                        <Switch
                            disabled={disabled}
                            value={userControlStartOnLogin}
                            onChange={props.onUserControlStartOnLoginChange}
                        />
                    </FormRow>
                )}
                {startOnLogin && (
                    <Banner
                        label={
                            <AppText
                                ls={{
                                    key: "tunnelSessionExpirationTime",
                                    replaceVals: [linkService.getLink("usersAndDeviceInactivity")],
                                }}
                            />
                        }
                        variant={Variant.INFORMATION}
                    />
                )}
                <SummaryTable serviceTunnel={props.serviceTunnel} />
            </div>
        </div>
    )
}

interface PolicyModeToggleProps {
    policyAttachment: PolicyAttachment
    disabled?: boolean
    onPolicyAttachmentChange?: (policyAttachment: PolicyAttachment) => void
}

function PolicyModeToggle(props: PolicyModeToggleProps): JSX.Element {
    const { enabled } = props.policyAttachment

    const policyMode = enabled ? PolicyMode.ENFORCING : PolicyMode.PERMISSIVE

    const localization = useServiceLocalization()
    const linkService = useServiceLinks()

    const onPoliceModeChange = (mode: PolicyMode) =>
        props.onPolicyAttachmentChange?.({
            ...props.policyAttachment,
            enabled: mode === PolicyMode.ENFORCING,
        })

    const policyModeItems: ToggleButtonItem[] = React.useMemo(() => {
        return [
            {
                label: localization.getString("enforcing"),
                value: PolicyMode.ENFORCING,
                selected: policyMode === PolicyMode.ENFORCING,
                onClick: () => onPoliceModeChange(PolicyMode.ENFORCING),
            },
            {
                label: localization.getString("permissive"),
                value: PolicyMode.PERMISSIVE,
                selected: policyMode === PolicyMode.PERMISSIVE,
                onClick: () => onPoliceModeChange(PolicyMode.PERMISSIVE),
            },
        ]
    }, [policyMode])

    return (
        <FormRow
            label={localization.getString("whatModeShouldThePolicyBeIn")}
            description={
                <AppText
                    ls={{
                        key: "readMoreAboutEnforcingVsPermissive",
                        replaceVals: [linkService.getLink("managePolicyGuide")],
                    }}
                />
            }
        >
            <ToggleButton disabled={props.disabled} items={policyModeItems} />
        </FormRow>
    )
}

function createPolicyAttachment(policy: Policy, policyModeEnabled = true): PolicyAttachment {
    return {
        serviceId: "",
        serviceName: "",
        policyId: policy.id,
        policyName: policy.name,
        enabled: policyModeEnabled,
    }
}

import React from "react"
import classNames from "classnames/bind"

import { Button, ButtonType } from "../../../components/button/Button.component"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import configureImage from "../../../images/upgrade-idp.svg"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { useInterval } from "../../../pre-v3/utils/UseInterval.hook"
import {
    Org,
    SupportBundlesRequest as Request,
    SupportBundlesRequestStatus as Status,
    useRefreshSupportBundlesRequest,
    useRequestSupportBundles,
} from "../../../v3/services/MomOrgManagement.service"
import styles from "./OrgSupportBundlesTab.module.scss"
import { SupportBundlesTable, Api as SupportBundlesTableApi } from "./SupportBundlesTable.component"

interface Props {
    org: Org
}

export function OrgSupportBundlesTab(props: Props): JSX.Element {
    switch (props.org.latestSupportBundlesRequest?.status) {
        case Status.PENDING:
            return <PendingRequest {...props} request={props.org.latestSupportBundlesRequest} />
        case Status.PARTIAL:
            return <PartialRequest {...props} request={props.org.latestSupportBundlesRequest} />
        case Status.READY:
            return <ReadyRequest {...props} request={props.org.latestSupportBundlesRequest} />
        case undefined:
            return <NoSupportBundleRequested {...props} />
    }
}

function NoSupportBundleRequested(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <div className={classNames(styles.tabContent, styles.centered)}>
            <ConfigureImage />
            <p>{localization.getString("noSupportBundlesHaveYetToBeRequestedMessage")}</p>
            <RequestSupportBundlesButton {...props} />
        </div>
    )
}

interface WithRequestProps extends Props {
    request: Request
}

function PendingRequest(props: WithRequestProps): JSX.Element {
    const localization = useServiceLocalization()

    const errorToastRef = usePeriodicallyRefreshSupportBundles(props.org, props.request)

    return (
        <div className={classNames(styles.tabContent, styles.centered)}>
            <ConfigureImage />
            <p>{localization.getString("supportBundlesAreBeingGeneratedMessage")}</p>
            <ErrorToast ref={errorToastRef} />
        </div>
    )
}

function PartialRequest(props: WithRequestProps): JSX.Element {
    const localization = useServiceLocalization()

    const errorToastRef = usePeriodicallyRefreshSupportBundles(props.org, props.request)

    return (
        <div className={styles.tabContent}>
            <div className={styles.requestHeader}>
                <p>{localization.getString("supportBundlesAreBeingGeneratedMessage")}</p>
                <RequestSupportBundlesButton {...props} isHidden />
            </div>
            <SupportBundlesTable request={props.request} />
            <ErrorToast ref={errorToastRef} />
        </div>
    )
}

function ReadyRequest(props: WithRequestProps): JSX.Element {
    const tableRef = React.useRef<SupportBundlesTableApi>(null)

    const localization = useServiceLocalization()

    const onDownloadAll: React.MouseEventHandler = (event) => {
        event.preventDefault()
        tableRef.current?.downloadAll()
    }

    return (
        <div className={styles.tabContent}>
            <div className={styles.requestHeader}>
                <p>{localization.getString("supportBundlesAreReadyForDownload")}</p>
                <div className={styles.buttons}>
                    <Button buttonType={ButtonType.SECONDARY} onClick={onDownloadAll}>
                        {localization.getString("downloadAll")}
                    </Button>
                    <RequestSupportBundlesButton {...props} />
                </div>
            </div>
            <SupportBundlesTable request={props.request} ref={tableRef} />
        </div>
    )
}

function ConfigureImage(): JSX.Element {
    return <img src={configureImage} alt="" className={styles.configureImage} />
}

interface RequestSupportBundlesButtonProps extends Props {
    isHidden?: boolean
}

function RequestSupportBundlesButton(props: RequestSupportBundlesButtonProps): JSX.Element {
    const localization = useServiceLocalization()

    const errorToastRef = React.useRef<ToastApi>(null)

    const { mutate: requestSupportBundles, isLoading: isRequesting } = useRequestSupportBundles(
        props.org,
        {
            onError: (error) =>
                typeof error === "string"
                    ? errorToastRef.current?.openToast(error)
                    : console.error(error),
        }
    )

    const onClick: React.MouseEventHandler = async (event) => {
        event.preventDefault()
        requestSupportBundles()
    }

    return (
        <React.Fragment>
            <Button
                buttonType={ButtonType.PRIMARY}
                onClick={onClick}
                loading={isRequesting}
                className={props.isHidden ? styles.hideButton : undefined}
                aria-hidden={props.isHidden}
            >
                {localization.getString(
                    "requestSomething",
                    localization.getString("supportBundles")
                )}
            </Button>
            <ErrorToast ref={errorToastRef} />
        </React.Fragment>
    )
}

function usePeriodicallyRefreshSupportBundles(
    org: Org,
    request: Request
): React.RefObject<ToastApi> {
    const errorToastRef = React.useRef<ToastApi>(null)

    const { mutate: refreshRequest } = useRefreshSupportBundlesRequest(org, request, {
        onError: (error) =>
            typeof error === "string"
                ? errorToastRef.current?.openToast(error)
                : console.error(error),
    })

    useInterval(() => refreshRequest(), 2_000)

    return errorToastRef
}

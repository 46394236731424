import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"
import { addDays, differenceInCalendarDays } from "date-fns"

import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import {
    convertFromIsoDate,
    convertFromServerTimestamp,
    convertToServerTimestamp,
} from "../../utils/Date.utils"
import { FileSize, getFileSizeFromBytes } from "../../utils/FileSize.utils"
import {
    AccessTierBundlesRes,
    AccessTierStatusRes,
    LicenseInformationRes,
    MomApi,
    OrgBody,
    OrgRes,
    ProvisionStatusRes,
    ShieldStatusRes,
    StatusRes,
    SupportBundleFileRes,
    SupportBundleRes,
    SupportBundlesRequestStatusRes,
    SupportBundlesRes,
    TypeRes,
} from "../api/Mom.api"
import { Edition, editionResMap, editionToResMap } from "./shared/Edition"
import { ApiFunction } from "./shared/QueryKey"
import { SuperAdminApi } from "../api/SuperAdmin.api"
import { LicenseLevel, licenseTypeMap } from "./shared/LicenseInformation"

const SERVICE_NAME = "MomOrgManagement"

function getOrgByIdKey(id: string): QueryKey {
    return [SERVICE_NAME, ApiFunction.GET_ORG_BY_ID, id]
}

export function useGetOrgById(
    orgId: string,
    options?: QueryOptions<Org, unknown>
): UseQueryResult<Org> {
    const momApi = new MomApi()

    return useQuery({
        ...options,
        queryKey: getOrgByIdKey(orgId),
        queryFn: async () => {
            const [orgRes, licenseInformationRes, supportBundlesRes] = await Promise.all([
                momApi.getOrgById(orgId),
                momApi.getLicenseInformationByOrgId(orgId),
                getLatestSupportBundlesRequest(momApi, orgId),
            ])

            const parentMspOrgRes = orgRes.parent_org_id
                ? await momApi.getOrgById(orgRes.parent_org_id)
                : undefined

            return getOrgFromRes(orgRes, licenseInformationRes, parentMspOrgRes, supportBundlesRes)
        },
    })
}

export function useAddOrg(
    options?: QueryOptions<Org, unknown, NewOrg>
): UseMutationResult<Org, unknown, NewOrg> {
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation<Org, unknown, NewOrg>({
        ...options,
        mutationFn: async (newOrg: NewOrg): Promise<Org> =>
            getOrgFromRes(await momApi.addOrg(getOrgBodyFromNewOrg(newOrg)), null, undefined, null),
        onSuccess: (newOrg, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [SERVICE_NAME, ApiFunction.GET_ORG_LIST] })
            queryClient.setQueryData<Org>(getOrgByIdKey(newOrg.id), newOrg)
            options?.onSuccess?.(newOrg, variables, context)
        },
    })
}

export function useEditOrg(
    options?: QueryOptions<Org, unknown, Org>
): UseMutationResult<Org, unknown, Org> {
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (updatedOrg: Org) => {
            const [orgRes, licenseInformationRes] = await Promise.all([
                momApi.updateOrg(getOrgBodyFromOrg(updatedOrg)),
                momApi.getLicenseInformationByOrgId(updatedOrg.id),
            ])

            const parentMspOrgRes = orgRes.parent_org_id
                ? await momApi.getOrgById(orgRes.parent_org_id)
                : undefined

            return getOrgFromRes(
                orgRes,
                licenseInformationRes,
                parentMspOrgRes,
                updatedOrg.extra.supportBundlesRes
            )
        },
        onSuccess: (updatedOrg, variables, context) => {
            queryClient.setQueryData<Org>(getOrgByIdKey(updatedOrg.id), updatedOrg)
            options?.onSuccess?.(updatedOrg, variables, context)
        },
    })
}

export function useArchiveOrg(
    org: Org,
    options?: QueryOptions<void, unknown>
): UseMutationResult<void, unknown, void> {
    const isArchived = true
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async () => {
            await momApi.updateOrg(
                getOrgBodyFromOrg({
                    ...org,
                    featureFlags: { ...org.featureFlags, isArchived },
                })
            )
        },
        onSuccess: () => {
            queryClient.setQueryData<Org>(
                getOrgByIdKey(org.id),
                (prevOrg) => prevOrg && updateCachedOrgWithNewIsArchived(prevOrg, isArchived)
            )
            options?.onSuccess?.()
        },
    })
}

export function useUnarchiveOrg(
    org: Org,
    options?: QueryOptions<void, unknown>
): UseMutationResult<void, unknown, void> {
    const isArchived = false
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async () => {
            await momApi.updateOrg(
                getOrgBodyFromOrg({ ...org, featureFlags: { ...org.featureFlags, isArchived } })
            )
        },
        onSuccess: () => {
            queryClient.setQueryData<Org>(
                getOrgByIdKey(org.id),
                (prevOrg) => prevOrg && updateCachedOrgWithNewIsArchived(prevOrg, isArchived)
            )
            options?.onSuccess?.()
        },
    })
}

export function useDeleteOrg(
    org: Org,
    options?: QueryOptions<void, unknown>
): UseMutationResult<void, unknown, void> {
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async () => {
            await momApi.deleteOrg(org.id)
        },
        onSuccess: () => {
            queryClient.removeQueries({ queryKey: [SERVICE_NAME] })
            options?.onSuccess?.()
        },
    })
}

export function useGenerateImpersonateToken(
    org: Org,
    options?: QueryOptions<string, unknown>
): UseMutationResult<string, unknown, void> {
    const superAdminApi = new SuperAdminApi()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async () => {
            const { Message } = await superAdminApi.impersonate(org.name)
            return Message
        },
        onSuccess: (jwtToken, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [SERVICE_NAME] })
            options?.onSuccess?.(jwtToken, variables, context)
        },
    })
}

export function useGetMspOrgs(options?: QueryOptions<MspOrg[], unknown>): UseQueryResult<MspOrg[]> {
    const momApi = new MomApi()

    return useQuery({
        ...options,
        queryKey: [SERVICE_NAME, ApiFunction.GET_MSP_ORGS],
        queryFn: async () => {
            const orgs = await momApi.getOrgs()

            return orgs.reduce<MspOrg[]>((mspOrgs, orgRes) => {
                const maybeMspOrg = getMspOrgFromRes(orgRes)
                return maybeMspOrg ? [...mspOrgs, maybeMspOrg] : mspOrgs
            }, [])
        },
    })
}

export function useRequestSupportBundles(
    org: Org,
    options?: QueryOptions<SupportBundlesRequest, unknown>
): UseMutationResult<SupportBundlesRequest, unknown, void> {
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async () =>
            getSupportBundlesRequestFromRes(await momApi.requestOrgSupportBundles(org.id)),
        onSuccess: (supportBundlesRequest, variables, context) => {
            queryClient.setQueryData<Org>(
                getOrgByIdKey(org.id),
                (prevOrg) =>
                    prevOrg && {
                        ...prevOrg,
                        latestSupportBundlesRequest: supportBundlesRequest,
                    }
            )

            options?.onSuccess?.(supportBundlesRequest, variables, context)
        },
    })
}

export function useRefreshSupportBundlesRequest(
    org: Org,
    supportBundlesRequest: SupportBundlesRequest,
    options?: QueryOptions<SupportBundlesRequest, unknown>
): UseMutationResult<SupportBundlesRequest, unknown, void> {
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async () =>
            getSupportBundlesRequestFromRes(
                await momApi.getOrgSupportBundles(org.id, supportBundlesRequest.id)
            ),
        onSuccess: (supportBundlesRequest, variables, context) => {
            queryClient.setQueryData<Org>(
                getOrgByIdKey(org.id),
                (prevOrg) =>
                    prevOrg && {
                        ...prevOrg,
                        latestSupportBundlesRequest: supportBundlesRequest,
                    }
            )

            options?.onSuccess?.(supportBundlesRequest, variables, context)
        },
    })
}

export function useAssignMspOrg(
    childOrg: Org,
    options?: QueryOptions<void, unknown, MspOrg>
): UseMutationResult<void, unknown, MspOrg> {
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (parentMspOrg) => {
            await momApi.assignMspOrg({ child_org_id: childOrg.id, msp_org_id: parentMspOrg.id })
        },
        onSuccess: (emptyData, parentMspOrg, context) => {
            queryClient.setQueryData<Org>(
                getOrgByIdKey(childOrg.id),
                (prevOrg) => prevOrg && { ...prevOrg, parentMspOrg }
            )
            options?.onSuccess?.(emptyData, parentMspOrg, context)
        },
    })
}

export function useRemoveMspOrgAssignment(
    childOrg: Org,
    options?: QueryOptions<void, unknown>
): UseMutationResult<void, unknown, void> {
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async () => {
            if (!childOrg.parentMspOrg) {
                console.warn("Cannot remove MSP org assignment because org has no parent MSP org")
                return
            }

            await momApi.removeMspOrgAssignment({
                child_org_id: childOrg.id,
                msp_org_id: childOrg.parentMspOrg.id,
            })
        },
        onSuccess: () => {
            queryClient.setQueryData<Org>(
                getOrgByIdKey(childOrg.id),
                (prevOrg) => prevOrg && { ...prevOrg, parentMspOrg: undefined }
            )
            options?.onSuccess?.()
        },
    })
}

const ARCHIVAL_INCREMENT_BY_DAYS = 45

export function useIncrementArchivalDate(
    org: Org,
    options?: QueryOptions<Date, unknown, Date>
): UseMutationResult<Date, unknown, Date> {
    const momApi = new MomApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (archiveAt) => {
            const { archive_at } = await momApi.updateOrgSettings(org.id, {
                archive_at: convertToServerTimestamp(
                    addDays(archiveAt, ARCHIVAL_INCREMENT_BY_DAYS)
                ),
            })

            return convertFromServerTimestamp(archive_at)
        },
        onSuccess: (newArchiveAt, previousArchiveAt, context) => {
            queryClient.setQueryData<Org>(
                getOrgByIdKey(org.id),
                (prevOrg) => prevOrg && updateCachedArchiveAt(prevOrg, newArchiveAt)
            )
            options?.onSuccess?.(newArchiveAt, previousArchiveAt, context)
        },
    })
}

export function isArchivalDateIncrementAllowed(archiveAt: Date, today: Date): boolean {
    const nextYear = addDays(today, 365)
    return differenceInCalendarDays(nextYear, archiveAt) >= ARCHIVAL_INCREMENT_BY_DAYS
}

// Types

export interface NewOrg {
    name: string
    edition: Edition
    type: KnownOrgType
    internalOwnerEmailAddress?: string
    customerId: string
    networkConfiguration: NetworkConfiguration
    featureFlags: NewOrgFeatureFlags
    admin: {
        firstName: string
        lastName: string
        emailAddress: string
    }
}

export interface Org {
    id: string
    name: string
    status: Status
    createdAt: Date
    createdBy: string
    lastUpdatedAt: Date
    lastUpdatedBy: string
    featureModel: FeatureModel
    typeInformation: TypeInformation
    archiveAt?: Date
    internalOwnerEmailAddress?: string
    customerId: string
    parentMspOrg?: MspOrg
    networkConfiguration: NetworkConfiguration
    provisioningComponents: ProvisioningComponents
    latestSupportBundlesRequest?: SupportBundlesRequest
    featureFlags: OrgFeatureFlags
    extra: { orgRes: OrgRes; supportBundlesRes: SupportBundlesRes | null }
}

export enum Status {
    ACTIVE = "ACTIVE",
    PARTIAL_SUCCESS = "PARTIAL_SUCCESS",
    ERROR = "ERROR",
    IN_PROGRESS = "IN_PROGRESS",
    // TODO: Get rid of this
    UNKNOWN = "UNKNOWN",
}

const statusFromResMap: Record<StatusRes, Status> = {
    Success: Status.ACTIVE,
    PartialSuccess: Status.PARTIAL_SUCCESS,
    Failed: Status.ERROR,
    InProgress: Status.IN_PROGRESS,
}

export const statusToLabelDict: Record<Status, LanguageKey> = {
    [Status.ACTIVE]: "active",
    [Status.PARTIAL_SUCCESS]: "partialSuccess",
    [Status.ERROR]: "error",
    [Status.IN_PROGRESS]: "inProgress",
    [Status.UNKNOWN]: "unknown",
}

export enum OrgType {
    PRODUCTION = "PRODUCTION",
    STAGING = "STAGING",
    TRIAL = "TRIAL",
    INTERNAL_PERSISTENT = "INTERNAL_PERSISTENT",
    INTERNAL_TEMPORARY = "INTERNAL_TEMPORARY",
    // TODO: Get rid of this
    UNKNOWN = "UNKNOWN",
}

export type KnownOrgType = Exclude<OrgType, OrgType.UNKNOWN>

export const allOrgTypes: KnownOrgType[] = [
    OrgType.PRODUCTION,
    OrgType.STAGING,
    OrgType.TRIAL,
    OrgType.INTERNAL_PERSISTENT,
    OrgType.INTERNAL_TEMPORARY,
]

const orgTypeToResMap: Record<KnownOrgType, TypeRes> = {
    [OrgType.PRODUCTION]: "Production",
    [OrgType.STAGING]: "Staging",
    [OrgType.TRIAL]: "Trial",
    [OrgType.INTERNAL_PERSISTENT]: "InternalPersistent",
    [OrgType.INTERNAL_TEMPORARY]: "InternalTemporary",
}

export const orgTypeLabelDict: Record<KnownOrgType, LanguageKey> = {
    [OrgType.PRODUCTION]: "production",
    [OrgType.STAGING]: "staging",
    [OrgType.TRIAL]: "trial",
    [OrgType.INTERNAL_PERSISTENT]: "internalPersistent",
    [OrgType.INTERNAL_TEMPORARY]: "internalTemporary",
}

interface NetworkConfiguration {
    usesGlobalEdge: boolean
    usesPrivateEdge: boolean
}

export type TypeInformation = KnowTypeInformation | UnknownTypeInformation

interface KnowTypeInformation {
    type: KnownOrgType
}

interface UnknownTypeInformation {
    type: OrgType.UNKNOWN
    typeValue: string
}

export enum FeatureModelType {
    EDITION_BASED = "EDITION_BASED",
    LICENSE_BASED = "LICENSE_BASED",
}

type FeatureModel = EditionModel | LicenseModel

interface EditionModel {
    type: FeatureModelType.EDITION_BASED
    edition: Edition
}

interface LicenseModel {
    type: FeatureModelType.LICENSE_BASED
    spa: LicenseInformation
    sia: LicenseInformation
}

export interface LicenseInformation {
    level: LicenseLevel
    entitlementCount: number
}

interface ProvisioningComponents {
    knownComponents: {
        accessTier: AccessTierProvisioningStatus | undefined
        shield: ShieldProvisioningStatus | undefined
        userPool: ProvisionStatus | undefined
        orgData: ProvisionStatus | undefined
        registeredDomain: ProvisionStatus | undefined
        connector: ProvisionStatus | undefined
        inviteCode: ProvisionStatus | undefined
        userPoolDomain: ProvisionStatus | undefined
        user: ProvisionStatus | undefined
        defaultGroupsUser: ProvisionStatus | undefined
        userPoolClient: ProvisionStatus | undefined
        defaultGroupsUserPool: ProvisionStatus | undefined
        updateUserPool: ProvisionStatus | undefined
        inviteAdminUser: ProvisionStatus | undefined
        privateEdgeShield: ProvisionStatus | undefined
    }
    accessTierComponents?: Record<string, AccessTierProvisioningStatus>
    unknownComponents?: Record<string, AllStatuses>
}

export enum ProvisionStatus {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    ARCHIVED = "ARCHIVED",
    PENDING = "PENDING",
    DELETED = "DELETED",
}

enum ProvisioningStatusExtras {
    PROVISIONING = "provisioning",
    PROVISIONING_FAILED = "provisioningFailed",
    DELETION_FAILED = "deletionFailed",
}

enum AccessTierProvisioningStatusExtras {
    DNS_ENTRIES_FAILED = "dnsEntriesFailed",
}

type ShieldProvisioningStatus = ProvisionStatus | ProvisioningStatusExtras
type AccessTierProvisioningStatus =
    | ProvisionStatus
    | ProvisioningStatusExtras
    | AccessTierProvisioningStatusExtras

export type AllStatuses = ProvisionStatus | ShieldProvisioningStatus | AccessTierProvisioningStatus

const provisionStatusMap: Record<ProvisionStatusRes, ProvisionStatus> = {
    Success: ProvisionStatus.SUCCESS,
    Failed: ProvisionStatus.ERROR,
    Archived: ProvisionStatus.ARCHIVED,
    Pending: ProvisionStatus.PENDING,
    Deleted: ProvisionStatus.DELETED,
}

const shieldProvisioningStatusMap: Record<ShieldStatusRes, ShieldProvisioningStatus> = {
    Deleted: ProvisionStatus.DELETED,
    Failed: ProvisionStatus.ERROR,
    Pending: ProvisionStatus.PENDING,
    Success: ProvisionStatus.SUCCESS,
    Provisioning: ProvisioningStatusExtras.PROVISIONING,
    ProvisioningFailed: ProvisioningStatusExtras.PROVISIONING_FAILED,
    DeletionFailed: ProvisioningStatusExtras.DELETION_FAILED,
}

const accessTierProvisioningStatusMap: Record<AccessTierStatusRes, AccessTierProvisioningStatus> = {
    Deleted: ProvisionStatus.DELETED,
    Failed: ProvisionStatus.ERROR,
    Pending: ProvisionStatus.PENDING,
    Success: ProvisionStatus.SUCCESS,
    Provisioning: ProvisioningStatusExtras.PROVISIONING,
    ProvisioningFailed: ProvisioningStatusExtras.PROVISIONING_FAILED,
    DNSEntriesFailed: AccessTierProvisioningStatusExtras.DNS_ENTRIES_FAILED,
    DeletionFailed: ProvisioningStatusExtras.DELETION_FAILED,
}

const allProvisionStatusMap = {
    ...provisionStatusMap,
    ...accessTierProvisioningStatusMap,
    ...shieldProvisioningStatusMap,
}

export const provisionStatusToLabelDict: Record<AllStatuses, LanguageKey> = {
    [ProvisionStatus.SUCCESS]: "success",
    [ProvisionStatus.ERROR]: "error",
    [ProvisionStatus.ARCHIVED]: "archived",
    [ProvisionStatus.PENDING]: "pending",
    [ProvisionStatus.DELETED]: "deleted",
    [ProvisioningStatusExtras.PROVISIONING]: "provisioning",
    [ProvisioningStatusExtras.PROVISIONING_FAILED]: "provisioningFailed",
    [ProvisioningStatusExtras.DELETION_FAILED]: "deletionFailed",
    [AccessTierProvisioningStatusExtras.DNS_ENTRIES_FAILED]: "dnsEntriesFailed",
}

export interface SupportBundlesRequest {
    id: string
    status: SupportBundlesRequestStatus
    requestedAt: Date
    clusterBundles: ClusterBundles[]
}

export enum SupportBundlesRequestStatus {
    PENDING = "PENDING",
    PARTIAL = "PARTIAL",
    READY = "READY",
}

export interface ClusterBundles {
    clusterName: string
    shieldBundle?: SupportBundleInformation
    accessTierBundles: AccessTierBundles[]
}

export interface AccessTierBundles {
    accessTierName: string
    netagentInstanceBundles: NetagentInstanceBundle[]
}

export interface NetagentInstanceBundle {
    netagentName: string
    supportBundleInformation?: SupportBundleInformation
}

export interface SupportBundleInformation {
    downloadUrl: string
    lastModifiedAt: Date
    fileSize: FileSize
}

interface NewOrgFeatureFlags {
    enableAiAssistedAdminSearch: boolean
    enableApplicationDiscovery: boolean
    isMspOrg: boolean
    usesSonicWallCseIdp: boolean
}

interface OrgFeatureFlags extends NewOrgFeatureFlags {
    isArchived: boolean
    isSonicWallProvisioned: boolean
}

export interface MspOrg {
    id: string
    name: string
}

// Helper Functions

type CommonProp =
    | "org_name"
    | "edition"
    | "customer_id"
    | "global_edge"
    | "private_edge"
    | "is_ai_assist_enabled"
    | "is_appdiscovery_enabled"
    | "banyan_idp"
    | "is_msp_org"
    | "internal_owner"

function getCommonPropsForOrgBody(org: Org | NewOrg): Pick<OrgBody, CommonProp> {
    return {
        org_name: org.name,
        customer_id: org.customerId,
        global_edge: org.networkConfiguration.usesGlobalEdge,
        private_edge: org.networkConfiguration.usesPrivateEdge,
        is_ai_assist_enabled: org.featureFlags.enableAiAssistedAdminSearch,
        is_appdiscovery_enabled: org.featureFlags.enableApplicationDiscovery,
        banyan_idp: org.featureFlags.usesSonicWallCseIdp,
        is_msp_org: org.featureFlags.isMspOrg,
        internal_owner: org.internalOwnerEmailAddress || undefined,
    }
}

function getOrgBodyFromNewOrg(newOrg: NewOrg): OrgBody {
    return {
        ...getCommonPropsForOrgBody(newOrg),
        first_name: newOrg.admin.firstName,
        last_name: newOrg.admin.lastName,
        email: newOrg.admin.emailAddress,
        edition: editionToResMap[newOrg.edition],
        type: orgTypeToResMap[newOrg.type],
        archived: false,
        is_dns_filter_enabled: false,
        is_urlfiltering_enabled: false,
        archive_at: null,
    }
}

function getOrgBodyFromOrg(org: Org): OrgBody {
    return {
        ...getCommonPropsForOrgBody(org),
        org_name: org.name,
        edition:
            org.featureModel.type === FeatureModelType.EDITION_BASED
                ? editionToResMap[org.featureModel.edition]
                : org.extra.orgRes.edition,
        type:
            org.typeInformation.type === OrgType.UNKNOWN
                ? org.typeInformation.typeValue
                : orgTypeToResMap[org.typeInformation.type],
        archived: org.featureFlags.isArchived,
        is_dns_filter_enabled: org.extra.orgRes.is_dns_filter_enabled,
        is_urlfiltering_enabled: org.extra.orgRes.is_urlfiltering_enabled,
    }
}

function getOrgFromRes(
    orgRes: OrgRes,
    licenseInformation: LicenseInformationRes | null,
    parentMspOrgRes: OrgRes | undefined,
    supportBundlesRes: SupportBundlesRes | null
): Org {
    return {
        id: orgRes.org_id,
        name: orgRes.org_name,
        status: (orgRes.status && statusFromResMap[orgRes.status]) || Status.UNKNOWN,
        createdAt: convertFromServerTimestamp(orgRes.created_at),
        createdBy: orgRes.created_by,
        lastUpdatedAt: convertFromServerTimestamp(orgRes.last_updated_at),
        lastUpdatedBy: orgRes.last_updated_by,
        featureModel: getFeatureModel(orgRes, licenseInformation),
        typeInformation: getTypeInformation(orgRes),
        archiveAt: getArchiveAt(orgRes),
        internalOwnerEmailAddress: orgRes.internal_owner || undefined,
        customerId: orgRes.customer_id,
        parentMspOrg: parentMspOrgRes && {
            id: parentMspOrgRes.org_id,
            name: parentMspOrgRes.org_name,
        },
        provisioningComponents: getProvisioningStatus(orgRes),
        networkConfiguration: {
            usesGlobalEdge: orgRes.global_edge,
            usesPrivateEdge: orgRes.private_edge,
        },
        latestSupportBundlesRequest: supportBundlesRes
            ? getSupportBundlesRequestFromRes(supportBundlesRes)
            : undefined,
        featureFlags: {
            enableAiAssistedAdminSearch: orgRes.is_ai_assist_enabled,
            enableApplicationDiscovery: orgRes.is_appdiscovery_enabled,
            isArchived: orgRes.archived,
            isMspOrg: orgRes.is_msp_org,
            isSonicWallProvisioned: orgRes.is_mysonicwall,
            usesSonicWallCseIdp: orgRes.banyan_idp,
        },
        extra: { orgRes, supportBundlesRes },
    }
}

function getFeatureModel(
    orgRes: OrgRes,
    licenseInformationRes: LicenseInformationRes | null
): FeatureModel {
    if (licenseInformationRes) {
        return {
            type: FeatureModelType.LICENSE_BASED,
            spa: {
                level: licenseTypeMap[licenseInformationRes.spa],
                entitlementCount: licenseInformationRes.spa_users,
            },
            sia: {
                level: licenseTypeMap[licenseInformationRes.sia],
                entitlementCount: licenseInformationRes.sia_users,
            },
        }
    }

    return {
        type: FeatureModelType.EDITION_BASED,
        edition: editionResMap[orgRes.edition],
    }
}

function getTypeInformation(orgRes: OrgRes): TypeInformation {
    switch (orgRes.type) {
        case "Production":
            return { type: OrgType.PRODUCTION }

        case "Trial":
            return { type: OrgType.TRIAL }

        case "Staging":
            return { type: OrgType.STAGING }

        case "InternalPersistent":
            return { type: OrgType.INTERNAL_PERSISTENT }

        case "InternalTemporary":
            return { type: OrgType.INTERNAL_TEMPORARY }

        default:
            return { type: OrgType.UNKNOWN, typeValue: orgRes.type }
    }
}

function getArchiveAt(orgRes: OrgRes) {
    return orgRes.archive_at ? convertFromServerTimestamp(orgRes.archive_at) : undefined
}

function getProvisioningStatus(orgRes: OrgRes): ProvisioningComponents {
    const {
        access_tier,
        shield,
        user_pool,
        org_data,
        registered_domain,
        connector,
        invite_code,
        user_pool_domain,
        user,
        default_groups_user,
        user_pool_client,
        default_groups_user_pool,
        update_user_pool,
        invite_admin_user,
        private_edge_shield,
        ...otherStatusRes
    } = orgRes.provision_status

    const knownComponents: ProvisioningComponents["knownComponents"] = {
        accessTier: access_tier && accessTierProvisioningStatusMap[access_tier],
        shield: shield && shieldProvisioningStatusMap[shield],
        userPool: user_pool && provisionStatusMap[user_pool],
        orgData: org_data && provisionStatusMap[org_data],
        registeredDomain: registered_domain && provisionStatusMap[registered_domain],
        connector: connector && provisionStatusMap[connector],
        inviteCode: invite_code && provisionStatusMap[invite_code],
        userPoolDomain: user_pool_domain && provisionStatusMap[user_pool_domain],
        user: user && provisionStatusMap[user],
        defaultGroupsUser: default_groups_user && provisionStatusMap[default_groups_user],
        userPoolClient: user_pool_client && provisionStatusMap[user_pool_client],
        defaultGroupsUserPool:
            default_groups_user_pool && provisionStatusMap[default_groups_user_pool],
        updateUserPool: update_user_pool && provisionStatusMap[update_user_pool],
        inviteAdminUser: invite_admin_user && provisionStatusMap[invite_admin_user],
        privateEdgeShield: private_edge_shield && provisionStatusMap[private_edge_shield],
    }

    return Object.entries(otherStatusRes).reduce<ProvisioningComponents>(
        (acc, [component, status]) => {
            if (!status) return acc

            const provisionStatus = allProvisionStatusMap[status]

            const [accessTier, region, ...rest] = component.split("access_tier-")

            if (accessTier === "" && rest.length <= 0) {
                return {
                    ...acc,
                    accessTierComponents: {
                        ...acc.accessTierComponents,
                        [region]: provisionStatus,
                    },
                }
            }

            return {
                ...acc,
                unknownComponents: { ...acc.unknownComponents, [component]: provisionStatus },
            }
        },
        { knownComponents }
    )
}

async function getLatestSupportBundlesRequest(
    momApi: MomApi,
    orgId: string
): Promise<SupportBundlesRes | null> {
    try {
        return await momApi.getOrgSupportBundles(orgId, "latest")
    } catch (error) {
        if (error === MomApi.SUPPORT_BUNDLES_REQUEST_NOT_FOUND) {
            return null
        }

        throw error
    }
}

function getSupportBundlesRequestFromRes(
    supportBundlesRes: SupportBundlesRes
): SupportBundlesRequest {
    return {
        id: supportBundlesRes.id,
        status: supportBundlesRequestStatusMap[supportBundlesRes.status],
        requestedAt: convertFromIsoDate(supportBundlesRes.created),
        clusterBundles: Object.entries(supportBundlesRes.bundles ?? {}).map(
            mapClusterBundleFromRes
        ),
    }
}

const supportBundlesRequestStatusMap: Record<
    SupportBundlesRequestStatusRes,
    SupportBundlesRequestStatus
> = {
    pending: SupportBundlesRequestStatus.PENDING,
    partial: SupportBundlesRequestStatus.PARTIAL,
    ready: SupportBundlesRequestStatus.READY,
}

function mapClusterBundleFromRes([clusterName, clusterBundleRes]: [
    string,
    SupportBundleRes,
]): ClusterBundles {
    return {
        clusterName,
        shieldBundle: clusterBundleRes.shield
            ? getSupportBundleInformationFromRes(clusterBundleRes.shield)
            : undefined,
        accessTierBundles: Object.entries(clusterBundleRes.access_tiers).map(
            mapAccessTierBundleFromRes
        ),
    }
}

function mapAccessTierBundleFromRes([accessTierName, accessTierBundlesRes]: [
    string,
    AccessTierBundlesRes,
]): AccessTierBundles {
    return {
        accessTierName,
        netagentInstanceBundles: Object.entries(accessTierBundlesRes).map(
            mapNetagentInstanceBundleFromRes
        ),
    }
}

function mapNetagentInstanceBundleFromRes([netagentName, netagentBundleRes]: [
    string,
    SupportBundleFileRes | null,
]): NetagentInstanceBundle {
    return {
        netagentName,
        supportBundleInformation: netagentBundleRes
            ? getSupportBundleInformationFromRes(netagentBundleRes)
            : undefined,
    }
}

function getSupportBundleInformationFromRes(
    supportBundleRes: SupportBundleFileRes
): SupportBundleInformation {
    return {
        downloadUrl: supportBundleRes.url,
        lastModifiedAt: convertFromIsoDate(supportBundleRes.last_modified),
        fileSize: getFileSizeFromBytes(supportBundleRes.size),
    }
}

function getMspOrgFromRes(orgRes: OrgRes): MspOrg | undefined {
    if (orgRes.is_msp_org) {
        return {
            id: orgRes.org_id,
            name: orgRes.org_name,
        }
    }
}

function updateCachedOrgWithNewIsArchived(org: Org, isArchived: boolean): Org {
    return {
        ...org,
        featureFlags: { ...org.featureFlags, isArchived },
        extra: { ...org.extra, orgRes: { ...org.extra.orgRes, archived: isArchived } },
    }
}

function updateCachedArchiveAt(org: Org, archivalAt: Date): Org {
    return {
        ...org,
        archiveAt: archivalAt,
    }
}

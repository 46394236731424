import React from "react"
import { useHistory, useParams } from "react-router-dom"

import {
    ConfirmationModalWithoutRef,
    ModalApi,
} from "../../../components/modal/ConfirmationModal.component"
import { useAdminInfo } from "../../../hooks/useAdminInfo.hook"
import {
    useDeleteServiceTunnel,
    useGetApplicationListForServiceTunnel,
    useGetServiceTunnel,
    useGetNetworks,
    useGetEdgeClusters,
} from "../../../v3/services/ServiceTunnelV2.service"
import { PolicyType, useGetPoliciesByType } from "../../../v3/services/Policy.service"
import { formatRoutePath, ROUTE } from "../../../routes"
import { decodeID } from "../../../pre-v3/utils/Url.util"
import styles from "./ServiceTunnelEdit.module.scss"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { RegisteredServiceStatus } from "../../../v3/services/shared/RegisteredService"
import { StatusType } from "../../../v3/components/status/Status.component"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { Tooltip } from "../../../v3/components/tooltip/Tooltip.component"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { InformationLabel } from "../../../components/label/Label.component"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import { ServiceTunnelFormWrapper } from "./ServiceTunnelFormWrapper.component"

interface Props {
    isEditMode?: boolean
    enableATG: boolean
    hideApplication: boolean
    hideAccessTier: boolean
    canEditServiceTunnel?: boolean
    canDeleteServiceTunnel?: boolean
}

export function ServiceTunnelEdit(props: Props): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()
    const params = useParams<{ id: string }>()
    const modalRef = React.useRef<ModalApi<void>>(null)
    const toastRef = React.useRef<ToastApi>(null)

    const adminInfoQuery = useAdminInfo()

    const {
        data: applications,
        status: applicationsStatus,
        error: applicationsError,
        refetch: refetchApplications,
    } = useGetApplicationListForServiceTunnel(props.enableATG)

    const {
        data: serviceTunnel,
        status: serviceTunnelStatus,
        error: serviceTunnelError,
        refetch: refetchServiceTunnel,
    } = useGetServiceTunnel(decodeID(params.id), props.enableATG)

    const {
        status: networksStatus,
        data: networks,
        error: NetworksError,
        refetch: refetchNetworks,
    } = useGetNetworks(props.enableATG)

    const {
        status: edgeClustersStatus,
        data: edgeClusters,
        error: edgeClustersError,
        refetch: refetchEdgeClusters,
    } = useGetEdgeClusters()

    const {
        status: policiesStatus,
        data: policies,
        error: policyError,
        refetch: refetchPolicy,
    } = useGetPoliciesByType(PolicyType.TUNNEL)

    const { mutateAsync: onDeleteServiceTunnel, reset: resetDeleteErrorMsg } =
        useDeleteServiceTunnel(props.enableATG, {
            onError: (error) =>
                typeof error === "string"
                    ? toastRef.current?.openToast(error)
                    : console.error(error),
        })

    const onDelete: React.MouseEventHandler = (e) => {
        e.preventDefault()
        modalRef.current?.open()
    }

    const onConfirmDelete = async () => {
        await onDeleteServiceTunnel(serviceTunnel!)
        modalRef.current?.complete()
        history.push(ROUTE.SERVICE_TUNNELS)
    }

    const refetchData = () => {
        refetchServiceTunnel()
        refetchNetworks()
        refetchEdgeClusters()
        refetchPolicy()
        refetchApplications()
        resetDeleteErrorMsg()
    }

    if (
        adminInfoQuery.status === "error" ||
        serviceTunnelStatus === "error" ||
        networksStatus === "error" ||
        edgeClustersStatus === "error" ||
        policiesStatus === "error" ||
        applicationsStatus === "error"
    ) {
        return (
            <Page
                heading={localization.getString("serviceTunnel")}
                headerRight={<RefreshButton onRefresh={refetchData} />}
            >
                <Banner
                    label={
                        adminInfoQuery.status === "error"
                            ? String(adminInfoQuery.error)
                            : "" ||
                              String(NetworksError) ||
                              String(edgeClustersError) ||
                              String(policyError) ||
                              String(applicationsError) ||
                              String(serviceTunnelError)
                    }
                    variant={Variant.ERROR}
                />
            </Page>
        )
    }

    if (
        adminInfoQuery.status === "loading" ||
        networksStatus === "loading" ||
        edgeClustersStatus === "loading" ||
        serviceTunnelStatus === "loading" ||
        policiesStatus === "loading" ||
        applicationsStatus === "loading"
    ) {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("serviceTunnel")
                )}
            />
        )
    }

    return (
        <Page
            heading={serviceTunnel.name}
            autorun={serviceTunnel.autorun}
            headerRight={
                <ActionButtons
                    isEditMode={props.isEditMode}
                    paramsId={params.id}
                    onDelete={onDelete}
                    refetch={refetchData}
                    canEditServiceTunnel={props.canEditServiceTunnel}
                    canDeleteServiceTunnel={props.canDeleteServiceTunnel}
                />
            }
        >
            <ServiceTunnelFormWrapper
                applications={applications}
                networks={networks}
                edgeClusters={edgeClusters}
                policies={policies}
                serviceTunnel={serviceTunnel}
                isEditMode={props.isEditMode}
                hasGlobalEdgeConfigured={adminInfoQuery.data.isGlobalEdge}
                enableATG={props.enableATG}
                hideApplication={props.hideApplication}
                hideAccessTier={props.hideAccessTier}
            />

            <ConfirmationModal
                id={Id.DELETE_SERVICE_TUNNEL_DIALOG}
                title={localization.getString("deleteServiceTunnel")}
                onConfirm={onConfirmDelete}
                confirmButtonLabel={localization.getString("yesRemove")}
                isDestructive
                ref={modalRef}
            >
                {localization.getString("deleteServiceTunnelDescription")}
            </ConfirmationModal>
            <ErrorToast ref={toastRef} />
        </Page>
    )
}

type ActionButtonsProps = {
    isEditMode?: boolean
    paramsId: string
    onDelete: React.MouseEventHandler
    refetch: () => void
    canEditServiceTunnel?: boolean
    canDeleteServiceTunnel?: boolean
}

function ActionButtons(props: ActionButtonsProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <div className={styles.actions}>
            {props.canEditServiceTunnel && (
                <Tooltip title={localization.getString("edit")}>
                    <Button
                        asElement={ButtonElement.LINK}
                        icon={IconType.PEN}
                        buttonType={ButtonType.SECONDARY}
                        to={formatRoutePath(ROUTE.SERVICE_TUNNELS_EDIT, { id: props.paramsId })}
                        disabled={props.isEditMode}
                    />
                </Tooltip>
            )}
            {props.canDeleteServiceTunnel && (
                <Tooltip title={localization.getString("delete")}>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        icon={IconType.TRASH}
                        onClick={props.onDelete}
                        buttonType={ButtonType.DESTRUCTIVE}
                    />
                </Tooltip>
            )}
            <Tooltip title={localization.getString("refresh")}>
                <Button
                    asElement={ButtonElement.BUTTON}
                    icon={IconType.REDO}
                    onClick={props.refetch}
                    buttonType={ButtonType.SECONDARY}
                />
            </Tooltip>
        </div>
    )
}

interface RefreshButtonProps {
    onRefresh(): void
}

export function RefreshButton(props: RefreshButtonProps): JSX.Element {
    const localization = useServiceLocalization()

    const onRefresh: React.MouseEventHandler = (e) => {
        e.preventDefault()
        props.onRefresh()
    }

    const refreshLabel = localization.getString("refresh")

    return (
        <Tooltip title={refreshLabel}>
            <Button
                icon={IconType.REDO}
                onClick={onRefresh}
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                aria-label={refreshLabel}
            />
        </Tooltip>
    )
}

interface PageProps {
    heading: string
    headerRight?: React.ReactNode
    children?: React.ReactNode
    autorun?: boolean
}

function Page(props: PageProps): JSX.Element {
    const localization = useServiceLocalization()
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div className={styles.labelContainer}>
                    <PageHeading id={Id.HEADING}>{props.heading}</PageHeading>
                    {props.autorun && (
                        <InformationLabel>
                            {localization.getString("connectsOnLogin")}
                        </InformationLabel>
                    )}
                </div>
                {props.headerRight}
            </header>
            {props.children}
        </div>
    )
}

enum Id {
    HEADING = "heading",
    DELETE_SERVICE_TUNNEL_DIALOG = "deleteServiceTunnelDialog",
}

export const statusMap: Record<RegisteredServiceStatus, StatusType> = {
    [RegisteredServiceStatus.INACTIVE]: "disabled",
    [RegisteredServiceStatus.NO_POLICY]: "error",
    [RegisteredServiceStatus.POLICY_ENFORCING]: "success",
    [RegisteredServiceStatus.POLICY_PERMISSIVE]: "warning",
}

export const labelMap: Record<RegisteredServiceStatus, LanguageKey> = {
    [RegisteredServiceStatus.INACTIVE]: "inactive",
    [RegisteredServiceStatus.NO_POLICY]: "noPolicy",
    [RegisteredServiceStatus.POLICY_ENFORCING]: "policyEnforcing",
    [RegisteredServiceStatus.POLICY_PERMISSIVE]: "policyPermissive",
}

export const iconMap: Record<RegisteredServiceStatus, IconType> = {
    [RegisteredServiceStatus.INACTIVE]: IconType.PAUSE_CIRCLE,
    [RegisteredServiceStatus.NO_POLICY]: IconType.TIMES_CIRCLE,
    [RegisteredServiceStatus.POLICY_ENFORCING]: IconType.CHECK_SOLID,
    [RegisteredServiceStatus.POLICY_PERMISSIVE]: IconType.MINUS_CIRCLE,
}

const ConfirmationModal = React.forwardRef(ConfirmationModalWithoutRef)

import React from "react"

import { useRefCollection } from "../../../hooks/useRefCollection.hook"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import {
    AccessTierBundles,
    ClusterBundles,
    NetagentInstanceBundle,
    SupportBundlesRequest as Request,
    SupportBundleInformation as BundleInformation,
} from "../../../v3/services/MomOrgManagement.service"
import styles from "./SupportBundlesTable.module.scss"

export interface Api {
    downloadAll(): void
}

interface Props {
    request: Request
}

export const SupportBundlesTable = React.forwardRef<Api, Props>((props, ref) => {
    const localization = useServiceLocalization()

    const { getAllRefContents, getRef } = useRefCollection<ClusterBundlesRowApi>()

    React.useImperativeHandle(ref, () => ({
        downloadAll: () =>
            getAllRefContents().forEach((clusterBundlesRow) => clusterBundlesRow.downloadAll()),
    }))

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th rowSpan={2} />
                    <th colSpan={2}>{localization.getString("component")}</th>
                    <th colSpan={3}>{localization.getString("supportBundle")}</th>
                </tr>
                <tr>
                    <th>{localization.getString("accessTier")}</th>
                    <th>{localization.getString("netagentInstance")}</th>
                    <th>{localization.getString("fileSize")}</th>
                    <th>{localization.getString("lastModified")}</th>
                    <th>{localization.getString("downloadLink")}</th>
                </tr>
            </thead>
            <tbody>
                {props.request.clusterBundles.map((clusterBundles) => (
                    <ClusterBundlesRow
                        key={clusterBundles.clusterName}
                        clusterBundles={clusterBundles}
                        requestedAt={props.request.requestedAt}
                        ref={getRef(clusterBundles.clusterName)}
                    />
                ))}
            </tbody>
        </table>
    )
})

SupportBundlesTable.displayName = "SupportBundlesTable"

interface ClusterBundlesRowApi {
    downloadAll(): void
}

interface ClusterBundlesRowProps {
    clusterBundles: ClusterBundles
    requestedAt: Date
}

const ClusterBundlesRow = React.forwardRef<ClusterBundlesRowApi, ClusterBundlesRowProps>(
    (props, ref) => {
        const localization = useServiceLocalization()

        const shieldBundleRef = React.useRef<BundleInformationCellApi>(null)
        const { getAllRefContents, getRef } = useRefCollection<AccessTierBundlesRowApi>()

        React.useImperativeHandle(ref, () => ({
            downloadAll: () => {
                shieldBundleRef.current?.download()
                getAllRefContents().forEach((accessTierBundleRow) =>
                    accessTierBundleRow.downloadAll()
                )
            },
        }))

        const shieldCount = 1
        const totalNetagentInstanceCount = props.clusterBundles.accessTierBundles.reduce(
            addNetagentCountToTotal,
            0
        )

        return (
            <React.Fragment>
                <tr>
                    <td rowSpan={shieldCount + totalNetagentInstanceCount}>
                        {props.clusterBundles.clusterName}
                    </td>
                    <td colSpan={2}>{localization.getString("shield")}</td>
                    <BundleInformationCell
                        bundleInformation={props.clusterBundles.shieldBundle}
                        requestedAt={props.requestedAt}
                        ref={shieldBundleRef}
                    />
                </tr>
                {props.clusterBundles.accessTierBundles.map((accessTierBundles) => (
                    <AccessTierBundlesRow
                        key={accessTierBundles.accessTierName}
                        accessTierBundles={accessTierBundles}
                        requestedAt={props.requestedAt}
                        ref={getRef(accessTierBundles.accessTierName)}
                    />
                ))}
            </React.Fragment>
        )
    }
)

ClusterBundlesRow.displayName = "ClusterBundlesRow"

interface AccessTierBundlesRowApi {
    downloadAll(): void
}

interface AccessTierBundlesRowProps {
    accessTierBundles: AccessTierBundles
    requestedAt: Date
}

const AccessTierBundlesRow = React.forwardRef<AccessTierBundlesRowApi, AccessTierBundlesRowProps>(
    (props, ref) => {
        const { getAllRefContents, getRef } =
            useRefCollection<NetagentInstanceBundleRowContentApi>()

        React.useImperativeHandle(ref, () => ({
            downloadAll: () =>
                getAllRefContents().forEach((netagentInstanceBundleRowContent) =>
                    netagentInstanceBundleRowContent.download()
                ),
        }))

        const netagentInstanceCount = props.accessTierBundles.netagentInstanceBundles.length
        const [firstNetagentInstanceBundle, ...restNetagentInstanceBundles] =
            props.accessTierBundles.netagentInstanceBundles

        return (
            <React.Fragment>
                <tr>
                    <td rowSpan={netagentInstanceCount}>
                        {props.accessTierBundles.accessTierName}
                    </td>
                    <NetagentInstanceBundleRowContent
                        netagentInstanceBundle={firstNetagentInstanceBundle}
                        requestedAt={props.requestedAt}
                        ref={getRef(firstNetagentInstanceBundle.netagentName)}
                    />
                </tr>
                {restNetagentInstanceBundles.map((netagentInstanceBundle) => (
                    <tr key={netagentInstanceBundle.netagentName}>
                        <NetagentInstanceBundleRowContent
                            netagentInstanceBundle={netagentInstanceBundle}
                            requestedAt={props.requestedAt}
                            ref={getRef(netagentInstanceBundle.netagentName)}
                        />
                    </tr>
                ))}
            </React.Fragment>
        )
    }
)

AccessTierBundlesRow.displayName = "AccessTierBundlesRow"

interface NetagentInstanceBundleRowContentApi {
    download(): void
}

interface NetagentInstanceBundleRowContentProps {
    netagentInstanceBundle: NetagentInstanceBundle
    requestedAt: Date
}

const NetagentInstanceBundleRowContent = React.forwardRef<
    NetagentInstanceBundleRowContentApi,
    NetagentInstanceBundleRowContentProps
>((props, ref) => {
    const bundleInformationCellRef = React.useRef<BundleInformationCellApi>(null)

    React.useImperativeHandle(ref, () => ({
        download: () => bundleInformationCellRef.current?.download(),
    }))

    return (
        <React.Fragment>
            <td>{props.netagentInstanceBundle.netagentName}</td>
            <BundleInformationCell
                bundleInformation={props.netagentInstanceBundle.supportBundleInformation}
                requestedAt={props.requestedAt}
                ref={bundleInformationCellRef}
            />
        </React.Fragment>
    )
})

NetagentInstanceBundleRowContent.displayName = "NetagentInstanceBundleRowContent"

interface BundleInformationCellApi {
    download(): void
}

interface BundleInformationCellProps {
    bundleInformation?: BundleInformation
    requestedAt: Date
}

const BundleInformationCell = React.forwardRef<
    BundleInformationCellApi,
    BundleInformationCellProps
>((props, ref) => {
    const linkRef = React.useRef<HTMLAnchorElement>(null)

    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    React.useImperativeHandle(ref, () => ({
        download: () => linkRef.current?.click(),
    }))

    if (!props.bundleInformation) {
        return (
            <td colSpan={3}>
                {localization.getString(
                    "waitingOnSupportBundleRequestedAtSomething",
                    props.requestedAt.toLocaleString(locale)
                )}
            </td>
        )
    }

    return (
        <React.Fragment>
            <td>{props.bundleInformation.fileSize.toFormattedString(locale)}</td>
            <td>{props.bundleInformation.lastModifiedAt.toLocaleString(locale)}</td>
            <td>
                <a href={props.bundleInformation.downloadUrl} ref={linkRef} download>
                    {localization.getString(
                        "downloadSomething",
                        localization.getString("supportBundle")
                    )}
                </a>
            </td>
        </React.Fragment>
    )
})

BundleInformationCell.displayName = "BundleInformationCell"

function addNetagentCountToTotal(total: number, accessTierBundles: AccessTierBundles): number {
    return total + accessTierBundles.netagentInstanceBundles.length
}
